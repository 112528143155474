import { TextField, Typography } from "@mui/material";
import { SkeletonWrapper } from "mui-toolkit/SkeletonWrapper";
import React, { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";

export const MASK_NUMERIC = "numeric";
export const MASK_NUMBER_UNFORMATTED = "numeric_unformatted";
export const MASK_PERCENTAGE = "percentage";

const NumericFormatInputComponent = React.forwardRef(function NumericFormatInputComponent(
    props,
    ref,
) {
    const { onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            valueIsNumericString
        />
    );
});

const NumberUnformattedFormatInputComponent = React.forwardRef(function NumberUnformattedFormatInputComponent(
    props,
    ref,
) {
    const { onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
        />
    );
});

const PercentageFormatInputComponent = React.forwardRef(function NumericFormatInputComponent(
    props,
    ref,
) {
    const { onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            decimalScale={2}
            allowNegative={false}
            isNumericString
            thousandSeparator
        />
    );
});

export const getInputComponentType = (_maskType) => {
    if (_maskType == MASK_NUMERIC) {
        return NumericFormatInputComponent;
    } else if (_maskType == MASK_NUMBER_UNFORMATTED) {
        return NumberUnformattedFormatInputComponent;
    } else if (_maskType == MASK_PERCENTAGE) {
        return PercentageFormatInputComponent;
    } else {
        return null;
    }
}

export function StatefulControlledTextField({
    defaultValue,
    placeholder,

    isRequired,
    isEditable,
    disabled,

    onChange,
    onBlur,

    // Pass-thru props:
    label,
    name,
    inputProps,
    InputLabelProps,
    variant,
    maskType,
    fullWidth,

    className,
    size,
    isLoading,
    sx,

    endAdornment
}) {
    const [value, setValue] = useState(defaultValue);

    const [isEdited, setIsEdited] = useState(false);

    const handleChangeState = (event, value) => {
        if (value == null && event.target.value != null) {
            value = event.target.value
        }
        if (value != null) {
            setValue(value);
        }
    }

    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);

    const getIsError = () => {
        if (isRequired) {
            return (!value)
        }
    }

    const getErrorText = () => {
        if (getIsError() && isEdited) {
            return "Required";
        }
    }

    const handleOnKeyUp = (e) => {
        const KEY_CODE_ENTER = 13;
        if (e.keyCode == KEY_CODE_ENTER) {
            handleOnBlur(e);
            e.target.select();
        }
    }

    const handleOnBlur = (e) => {
        e.target.value = value;

        setIsEdited(true);
        if (onChange) {
            onChange(e);
        } else {
            onBlur && onBlur(e);
        }
    }

    return (
        <SkeletonWrapper variant="rectangle" isLoading={isLoading}>
            {(isEditable != false || isLoading) &&
                <TextField
                    value={value}
                    onFocus={e => e.target.select()}
                    onChange={handleChangeState}
                    onBlur={handleOnBlur}

                    label={label}
                    placeholder={placeholder}
                    name={name}
                    variant={variant}
                    inputProps={inputProps}
                    InputLabelProps={InputLabelProps}
                    fullWidth={fullWidth}

                    className={className}

                    disabled={disabled}

                    sx={{ ...sx }}
                    size={size}

                    onKeyUp={handleOnKeyUp}

                    error={getIsError()}
                    helperText={getErrorText()}

                    InputProps={{
                        endAdornment: endAdornment,

                        id: null,
                        autoComplete: "off",
                        form: {
                            autocomplete: "off"
                        },

                        inputComponent: getInputComponentType(maskType)
                    }}

                    autoComplete='off'
                />}
            {isEditable == false && !isLoading &&
                <Typography variant="body1" sx={{ borderBottom: "1px solid #efefef", p: 0.50, ...inputProps?.sx }}>
                    {value || ""}
                </Typography>}
        </SkeletonWrapper>
    );
}