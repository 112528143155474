import { useDialog } from '@datanac/datanac-mui-toolkit'
import { CircularProgress, useMediaQuery } from '@mui/material'
import { AppContext } from 'App'
import EmptyCard from 'components/Cards/EmptyCard'
import { propUpdateReducer } from 'components/Core/propUpdateReducer'
import { FarmVaultIcon } from 'components/Icons/Icons'
import { useFarmController } from 'components/Vault/Farm/useFarmController'
import WorkspaceHeader from 'components/Workspaces/WorkspaceHeader'
import { WORKSPACE_VIEW_CARD, WORKSPACE_VIEW_TABLE } from 'components/Workspaces/helpers'
import { divIcon } from 'leaflet'
import 'leaflet/dist/leaflet.css'
import { useSnackbar } from 'notistack'
import { useContext, useEffect, useReducer, useState } from 'react'
import { GeoJSON, MapContainer, TileLayer } from 'react-leaflet'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { getCurrentReinsuranceYear } from '@datanac/datanac-api-toolkit/dist/utility/InsurancePlanHelper'

export default function AcresMapReport() {
    const { globalState, globalDispatch } = useContext(AppContext);
    
    const navigate = useNavigate();

    const location = useLocation();

    // ---

    const [filterLocationStateName, setFilterLocationStateName] = useState(null);
    const [filterLocationCountyName, setFilterLocationCountyName] = useState(null);

    //useEffect for location?.search - querystring changes
    useEffect(() => {
        const searchParams = new URLSearchParams(location?.search);
        const location_state_name = searchParams.get('location_state_name');
        if (location_state_name?.length) {
            setFilterLocationStateName(location_state_name);
        } else {
            setFilterLocationStateName(null);
        }
    }, [location?.search]);
    useEffect(() => {
        const searchParams = new URLSearchParams(location?.search);
        const location_county_name = searchParams.get('location_county_name');
        if (location_county_name?.length) {
            setFilterLocationCountyName(location_county_name);
        } else {
            setFilterLocationCountyName(null);
        }
    }, [location?.search]);

    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'), { noSsr: true });
    const { component: confirmDialogComponent, actions: confirmDialogActions } = useDialog();

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const handleError = (err) => {
        console.warn(err);
        enqueueSnackbar(err.detail || err.message || err, { preventDuplicate: true, variant: 'error' });
    }

    const [workspaceMode, setWorkspaceMode] = useState(isSmall ? WORKSPACE_VIEW_CARD : WORKSPACE_VIEW_TABLE);

    const [currentYear, setCurrentYear] = useState(globalState?.crop_year ? globalState?.crop_year : getCurrentReinsuranceYear());
    useEffect(() => {
        globalDispatch({
            type: 'update',
            payload: {
                key: 'crop_year',
                value: currentYear
            },
        });
    }, [currentYear])

    const [filter, dispatchFilter] = useReducer(propUpdateReducer, {
        commodity_name: [],
        practice_name: [],
    });

    const {
        counties,
        isLoadingCimsGeometry,
        cimsGeometry,
    } = useFarmController({
        crop_year: currentYear,
        filter: filter,
        doLoadCIMS: true,/* Load CIMS */
        onError: handleError
    });

    const calculateCenter = (coordinates) => {
        const totalPoints = coordinates.length;

        const sum = coordinates.reduce((acc, point) => {
            acc[0] += point[0];
            acc[1] += point[1];
            return acc;
        }, [0, 0]);

        const meanX = sum[0] / totalPoints;
        const meanY = sum[1] / totalPoints;

        return [meanX, meanY];
    };

    const customMarkerIcon = (name) =>
        divIcon({
            html: name,
            className: "icon"
        });

    const setIcon = ({ properties }, latlng) => {
        return L.marker(latlng, { icon: customMarkerIcon(properties.Name) });
    };
    const setColor = ({ properties }) => {
        return { weight: 1 };
    };

    const [mapGeoJsonLabels, setMapGeoJsonLabels] = useState(null);
    const [mapGeoJson, setMapGeoJson] = useState(null);
    const [mapCenter, setMapCenter] = useState(null);
    useEffect(() => {
        if (cimsGeometry?.length) {
            setMapGeoJson(
                {
                    "type": "FeatureCollection",
                    "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
                    "features": cimsGeometry?.map(geom => (
                        {
                            "type": "Feature",
                            "properties": {
                                "altitudeMode": "clampToGround",
                                "tessellate": "-1",
                                "extrude": "0",
                                "visibility": "-1",
                            },
                            "geometry": {
                                "type": "Polygon",
                                "coordinates": geom?.geometry?.coordinates
                            }
                        }
                    ))
                }
            );

            setMapGeoJsonLabels(
                {
                    "type": "FeatureCollection",
                    "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
                    "features": cimsGeometry?.map(geom => (
                        {
                            "type": "Feature",
                            "properties": {
                                "Name": `F&nbsp;${geom?.fsa_farm_number || ""} \n T&nbsp;${geom?.fsa_tract_number || ""}.${geom?.fsa_field_number || ""}`,
                                "altitudeMode": "clampToGround",
                                "tessellate": "-1",
                                "extrude": "0",
                                "visibility": "-1",
                                "snippet": ""
                            },
                            "geometry": {
                                "type": "Point",
                                "coordinates": calculateCenter(geom?.geometry?.coordinates[0])
                            }
                        }
                    ))
                }
            );

            const _mapCenter = cimsGeometry[0]?.geometry?.coordinates[0][0];
            // Reverse coords for Leaflet; do not mutate original. do not use reverse()
            const _mapCenterReversed = [..._mapCenter].reverse();
            setMapCenter(_mapCenterReversed);
        }
    }, [cimsGeometry]);

    // ---

    useEffect(() => {
        // Reducer counties[ii].ProducerFarmCrops
        var producerFarmCrops = [];
        counties
            ?.filter((county) => {
                const isCountyMatch = filterLocationCountyName == null || county?.location_county_name == filterLocationCountyName;
                const isStateMatch = filterLocationStateName == null || county?.location_state_name == filterLocationStateName;
                return isCountyMatch && isStateMatch;
            })
            ?.forEach((county) => {
                county?.ProducerFarms?.forEach((producerFarm) => {
                    producerFarm?.ProducerFarmFieldCrops?.forEach((producerFarmFieldCrop) => {
                        producerFarmCrops.push({
                            ...producerFarmFieldCrop,
                            CustomerName: producerFarm?.customer_name,
                            OrderDate: producerFarm?.order_date,
                            Freight: producerFarm?.freight,
                            ShippedDate: producerFarm?.shipped_date,
                        });
                    });
                });
            });
    }, [counties, filterLocationStateName, filterLocationCountyName]);

    // ---

    const filterHandleChangeState = (event, value) => {
        if (value == null && event.target.value != null) {
            value = event.target.value
        }

        dispatchFilter({
            type: 'update',
            payload: { key: event.target.name, value: value },
        });
    }

    // ---

    const handleFarmNavigateClick = (_currentProducerFarm) => {
        navigate(`/vault/${currentYear}/${_currentProducerFarm?.location_state_name}/${_currentProducerFarm?.location_county_name}/${_currentProducerFarm?.id}`)
    }

    // ---

    return (<>
        <WorkspaceHeader
            title={"Acres Map Report"}
            breadCrumbs={[
                {
                    title: 'Farm Vault',
                    path: '/vault',
                    icon: <FarmVaultIcon fontSize='3pt' />
                },
            ]}
        />

        {!isLoadingCimsGeometry && !Boolean(cimsGeometry?.length) && <>
            <EmptyCard text="No results were found" secondaryText="We could not find any maps in the Vault.  Please ask your insurance agent to help get your information in the federal databases." />
        </>}

        {isLoadingCimsGeometry && <CircularProgress />}

        {Boolean(mapCenter) &&
            <MapContainer
                style={{ height: "80vh", width: "100%" }}
                zoom={12}
                center={mapCenter}
            >
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='osm.org'
                />
                {mapGeoJson && <GeoJSON key="map01" data={mapGeoJson} pointToLayer={setColor} />}
                {mapGeoJsonLabels && <GeoJSON key="map02" data={mapGeoJsonLabels} pointToLayer={setIcon} />}
            </MapContainer>}

        {confirmDialogComponent}
    </>);
}