import { ApiHelper, USER_ROLE_ADMIN } from '@datanac/datanac-api-toolkit/dist/api-helper';
import { useDialog } from '@datanac/datanac-mui-toolkit/dist/hooks';
import { ArrowDropDownOutlined, Delete, Refresh } from '@mui/icons-material';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { Autocomplete, Box, Button, CircularProgress, Dialog, Divider, FormControl, IconButton, Menu, MenuItem, Paper, Stack, TextField, Tooltip, Typography, useMediaQuery } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { _sessionManager, UsersApiHelper } from 'api/ApiHelper';
import { AppContext } from 'App';
import EmptyCard from 'components/Cards/EmptyCard';
import { propUpdateReducer } from 'components/Core/propUpdateReducer';
import { StatefulControlledTextField } from 'components/Core/StatefulControlledTextField';
import { FarmVaultIcon } from 'components/Icons/Icons';
import AgentAutoComplete from 'components/Marketing/AgentAutoComplete';
import WorkspaceHeader from 'components/Workspaces/WorkspaceHeader';
import _ from 'lodash';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { formatDateLong, formatNumber, formatPrice, formatProducerName } from 'utility/formatters';
import { v4 } from 'uuid';
import { CropProfileEditDialog } from './CropProfileEditDialog';
import { useFormStatus } from 'mui-toolkit/useFormStatus'

export default function TransactionImportWorkspace() {
    const { globalState, globalDispatch } = useContext(AppContext);
    const { enqueueSnackbar } = useSnackbar();

    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('lg'), { noSsr: true });
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'), { noSsr: true });

    const { component: confirmDialogComponent, actions: confirmDialogActions } = useDialog();
    const { component: formStatusComponent, actions: formStatusActions, state: formStatusState } = useFormStatus();

    // ---

    const [brokers, setBrokers] = useState(null);
    const [isLoadingBrokers, setIsLoadingBrokers] = useState(false);
    const [isLoadingBatchApprove, setIsLoadingBatchApprove] = useState(false);


    const [editingCropProfile, setEditingCropProfile] = useState(null);

    const handleError = (err) => {
        console.warn(err);
        enqueueSnackbar(err.detail || err.message || JSON.stringify(err), { preventDuplicate: true, variant: 'error' });
    }

    const loadBrokers = async () => {
        try {
            if (globalState?.agent_token) {
                setIsLoadingBrokers(true);

                const _brokersFilter = {
                    agent_token: globalState?.agent_token,
                };
                // This is agent function. Do not specify producer_token or you will get permissions error.
                const _apiConfig = {
                    agent_token: globalState?.agent_token,
                };
                const _brokers = await UsersApiHelper.users_search("AgencyAgent", _brokersFilter, _apiConfig);
                setBrokers(_brokers);
                setIsLoadingBrokers(false);
            } else if (globalState?.user_role == USER_ROLE_ADMIN) {
                setIsLoadingBrokers(true);

                const _brokersFilter = {};
                // This is ADMIN function. Do not specify producer_token or agent_token or you will get permissions error.
                const _apiConfig = {};
                const _brokers = await UsersApiHelper.users_search("AgencyAgent", _brokersFilter, _apiConfig);
                setBrokers(_brokers);
                setIsLoadingBrokers(false);
            }
        } catch (err) {
            setIsLoadingBrokers(false);
            setBrokers(null);
            handleError(err);
        }
    }

    useEffect(() => {
        if (globalState?.agent_token || globalState?.user_role == USER_ROLE_ADMIN) {
            setIsLoadingBrokers(true);
            loadBrokers();
        }
    }, [globalState?.agent_token, globalState?.user_role]);

    // ---

    const [cropProfiles, setCropProfiles] = useState(null);
    const [isLoadingCropProfiles, setIsLoadingCropProfiles] = useState(false);

    const buildCropProfilesDictionaryByProducer = (_cropProfiles) => {
        const _dictionary = {};
        // Populate: _dictionary[producer_token] should contain all bcp {year, commodity_name, crop_profile}
        _cropProfiles.forEach(cp => {
            if (!_dictionary[cp.producer_token]) {
                _dictionary[cp.producer_token] = [];
            }

            const cropProfileDescription = cp.year + " - " + cp.commodity_name +
                (cp?.name && cp?.name != cp?.commodity_name ? " - " + cp.name : "");

            const _entry = {
                year: cp.year,
                commodity_name: cp.commodity_name,
                crop_profile: cropProfileDescription,
            };

            _dictionary[cp.producer_token].push(_entry);
        });
        return _dictionary;
    }

    // Memoize crop_profile from cropProfiles
    const cropProfilesDictionary = useMemo(() =>
        (cropProfiles?.length &&
            buildCropProfilesDictionaryByProducer(cropProfiles)
        ) || null,
        [cropProfiles]);

    const getCropProfileLabelsByProducerToken = useCallback(
        (producer_token, commodity_name) => {
            const _producerCropProfiles = cropProfilesDictionary?.[producer_token] || [];

            if (_producerCropProfiles?.length) {
                return _producerCropProfiles
                    // ?.filter(cp => cp?.commodity_name === commodity_name) // Strict equality check
                    ?.map(cp => cp?.crop_profile) || [];
            } else {
                return [];
            }
        },
        [cropProfilesDictionary]);

    const loadCropProfiles = async () => {
        try {
            setIsLoadingCropProfiles(true);

            const _filter = {
                agent_token: globalState?.agent_token,
                is_posted: filter?.filter_is_posted || false,
            };

            const promises = filter.filter_agent_token.map(async (agentToken) => {
                if (filter?.filter_agent_token && filter?.filter_agent_token.length) {
                    _filter.filter_agent_token = agentToken;
                }

                return await UsersApiHelper.users_search("MarketTransactionHistoryCropProfile", _filter, globalState);
            });

            const allResults = await Promise.all(promises);

            setCropProfiles(allResults.flat());
            setIsLoadingCropProfiles(false);
        } catch (err) {
            setIsLoadingCropProfiles(false);
            setCropProfiles(null);
            handleError(err);
        }
    }

    // ---

    const [transactionHistory, setTransactionHistory] = useState([]);
    const [isLoadingTransactionHistory, setIsLoadingTransactionHistory] = useState(false);

    const transactionHistoryTradeDates = useMemo(() =>
        (transactionHistory?.length &&
            // Sort by date asc
            _.uniq(transactionHistory?.map(hist => hist?.trade_date))
                .sort((a, b) => new Date(a) - new Date(b))
        ) || null,
        [transactionHistory]);

    const loadTransactionHistory = async () => {
        try {
            setIsLoadingTransactionHistory(true);

            const _filter = {
                agent_token: globalState?.agent_token,
                is_posted: filter?.filter_is_posted || false,
            };

            const promises = filter.filter_agent_token.map(async (agentToken) => {
                if (filter?.filter_agent_token && filter?.filter_agent_token.length) {
                    _filter.filter_agent_token = agentToken;
                }

                const apiClaims = {};
                if (globalState?.user_role != USER_ROLE_ADMIN) {
                    apiClaims.agent_token = globalState?.agent_token;
                }

                return await UsersApiHelper.selectMarketTransactionHistory(_filter, globalState);
            });

            const allResults = await Promise.all(promises);
            const allResultsFlat = allResults.flat();

            // Assign crop_profile = getDefaultCropProfile(params?.row)
            allResultsFlat.forEach(hist => hist.crop_profile = hist.crop_profile || getDefaultCropProfile(hist));

            // Sort by trade date ASC:
            // const _sortedTransactionHistory = transactionHistory.sort((a, b) => new Date(a.trade_date) - new Date(b.trade_date));

            setTransactionHistory(allResultsFlat);
            setIsLoadingTransactionHistory(false);
        } catch (err) {
            setIsLoadingTransactionHistory(false);
            setTransactionHistory(null);
            handleError(err);
        }
    }

    const handleDispatchRowChange = useCallback(async (id, key, value) => {
        const _transactionHistory = [...transactionHistory];
        const _index = _transactionHistory.findIndex(hist => hist.id == id);
        if (_index > -1) {
            _transactionHistory[_index][key] = value;
            setTransactionHistory(_transactionHistory);
        }
    }, [transactionHistory, setTransactionHistory]);

    // ---

    const [filter, dispatchFilter] = useReducer(propUpdateReducer, {
        filter_agent_token: globalState?.filter_agent_token || [globalState?.agent_token] || [],
        filter_is_posted: false,
    });
    const selectedBroker = useMemo(() => {
        const _selectedBroker = brokers?.filter(broker =>
            filter?.filter_agent_token?.includes(broker?.agent_token.toLowerCase())
        ) || [];

        return _selectedBroker;
    }, [brokers, filter?.filter_agent_token, globalState?.agent_token]);

    const handleChangeAgentToken = async (agentTokens) => {
        let agents = agentTokens.map((item) => (item.agent_token));

        dispatchFilter({
            type: 'update',
            payload: { key: 'filter_agent_token', value: agents },
        });
    }

    useEffect(() => {
        globalDispatch({
            type: 'update',
            payload: {
                key: "filter_agent_token",
                value: filter?.filter_agent_token,//array
            },
        });
    }, [filter?.filter_agent_token]);

    const filterHandleChangeState = (event, value) => {
        if (value == null && event.target.value != null) {
            value = event.target.value
        }

        dispatchFilter({
            type: 'update',
            payload: { key: event.target.name, value: value },
        });
    }

    const handleRowApprove = useCallback(async (row) => {
        if (row?.crop_profile) {
            // Lookup crop profile from cropProfiles
            const _cropProfile = cropProfilesDictionary?.[row?.producer_token]?.find(cp => cp?.crop_profile == row?.crop_profile) || null;

            let _cropProfileYear;
            let _cropProfileCommodityName;

            // If not configured, create one:
            if (_cropProfile) {
                _cropProfileYear = _cropProfile?.year;
                _cropProfileCommodityName = _cropProfile?.commodity_name;

                if (_cropProfileYear && _cropProfileCommodityName) {
                    const _row = { ...row };
                    _row.crop_profile_year = _cropProfileYear;
                    _row.crop_profile_commodity_name = _cropProfileCommodityName;
                    _row.is_posted = true;

                    const api = new ApiHelper({
                        baseUrl: process.env.REACT_APP_DATANAC_USERS_API_URL_BASE,
                        config: {},
                        sessionManager: _sessionManager,
                        // No caching:
                        _instance: null
                    });
                    await api.callPost("broker/MarketTransactionHistory", _row, globalState);

                    handleDispatchRowChange(row?.id, 'is_posted', true);
                }
            } else {
                // // Parse
                // const _cropProfileParts = row?.crop_profile.split(" - ");
                // if (_cropProfileParts.length == 2) {
                //     _cropProfileYear = parseInt(_cropProfileParts[0]);
                //     _cropProfileCommodityName = _cropProfileParts[1];
                // }
                enqueueSnackbar("Crop profile not found. Please create a new one.", { preventDuplicate: true, variant: 'error' });
                return;
            }
        }
    }, [cropProfilesDictionary, handleDispatchRowChange]);

    const handleRowDelete = useCallback(async (row) => {
        confirmDialogActions.confirm({
            title: "Delete this entry?",
            prompt: "Are you sure you want to delete this entry?",
            promptDetail: null,
            callback: () => {
                _handleRowDeleteIntern(row);
            }
        })
    }, [confirmDialogActions]);

    const _handleRowDeleteIntern = useCallback(async (row) => {
        const _row = { ...row };
        _row.crop_profile = null;
        _row.crop_profile_year = null;
        _row.crop_profile_commodity_name = null;
        _row.is_posted = false;

        const api = new ApiHelper({
            baseUrl: process.env.REACT_APP_DATANAC_USERS_API_URL_BASE,
            config: {},
            sessionManager: _sessionManager,
            // No caching:
            _instance: null
        });
        await api.callPost("broker/MarketTransactionHistory", _row, globalState);

        handleDispatchRowChange(row?.id, 'crop_profile', null);
        handleDispatchRowChange(row?.id, 'is_posted', false);
    }, [handleDispatchRowChange]);

    useEffect(() => {
        if (globalState?.agent_token ||
            (globalState?.user_role == USER_ROLE_ADMIN && filter?.filter_agent_token)
        ) {
            setIsLoadingTransactionHistory(true);
            loadTransactionHistory();
        }
    }, [globalState?.agent_token, globalState?.user_role, filter]);

    useEffect(() => {
        if (globalState?.agent_token ||
            (globalState?.user_role == USER_ROLE_ADMIN && filter?.filter_agent_token)
        ) {
            setIsLoadingCropProfiles(true);
            loadCropProfiles();
        }
    }, [globalState?.agent_token, globalState?.user_role, filter]);

    // ---

    const getDefaultCropProfile = (row) => {
        if (row?.is_posted == null) {
            if (row?.crop_profile_year && row?.crop_profile_commodity_name) {
                const fullName = row?.crop_profile_year + " - " + row?.crop_profile_commodity_name +
                    (row?.crop_profile_name != row?.crop_profile_commodity_name ? " - " + row?.crop_profile_name : "");
                return fullName;
            } else {
                return null;
            }
        } else {
            return row?.crop_profile || null;
        }
    }

    const [selectedTransaction, setSelectedTransaction] = useState(null);

    const getCropProfileFromTransactionHistory = (row) => {
        const _cropProfile = {
            id: v4(),
            producer_token: row?.producer_token,
            is_active: true,
        };
        if (row?.symbol_year && row?.commodity_name) {
            let _cropProfileYear = row?.symbol_year;
            if (row?.commodity_reference_month_term) {
                _cropProfileYear += row?.commodity_reference_month_term;
            }
            if (row?.commodity_reference_month && row?.symbol_month >= row?.commodity_reference_month) {
                _cropProfileYear++;
            }
            _cropProfile.year = _cropProfileYear;
            _cropProfile.commodity_name = row?.commodity_name;
        }

        setSelectedTransaction(row.id);

        return _cropProfile;
    }

    // ---
    const handleChange_CropProfile = async (_newCropProfile) => {
        try {
            const api = new ApiHelper({
                baseUrl: process.env.REACT_APP_DATANAC_USERS_API_URL_BASE,
                config: {},
                sessionManager: _sessionManager,
                // No caching:
                _instance: null
            });
            await api.callPost("marketing/CropProfile", _newCropProfile, globalState);

            loadCropProfiles();
        } catch (err) {
            handleError(err);
        }
    }

    const [groupedTransactionHistoryData, setGroupedTransactionHistoryData] = useState({});

    useEffect(() => {
        const groupData = () => {
            if (filter.filter_is_posted)
                transactionHistory?.sort((a, b) => new Date(b.trade_date) - new Date(a.trade_date));

            return transactionHistory?.length && transactionHistory?.reduce((acc, item) => {
                const key = `${item.batch_name?.substring(item.batch_name?.length - 13)}-${formatDateLong(item.trade_date)}`;
                if (!acc[key]) acc[key] = [];
                acc[key].push({
                    ...item,         // Spread the item
                    showNote: false,
                });
                return acc;
            }, {});
        };
        setGroupedTransactionHistoryData(groupData());
    }, [transactionHistory]);

    const handleBatchApprove = useCallback(async (batchKey) => {
        const batchTransactions = groupedTransactionHistoryData[batchKey];
        if (!batchTransactions || batchTransactions.length === 0) {
            enqueueSnackbar(`No transactions found for batch ${batchKey}.`, { variant: 'warning' });
            return;
        }
        const failedTransactions = []; // Array to keep track of failed transactions    
        for (const transaction of batchTransactions) {
            try {
                setIsLoadingBatchApprove(true);
                await handleRowApprove(transaction); // Attempt to approve the transaction
            } catch (error) {
                console.warn(`Error approving transaction with ID ${transaction.id}:`, error);
                // Log the failed transaction
                failedTransactions.push({
                    transaction,
                    error: error.message || "Unknown error",
                });
                // Notify the user and stop the process
                enqueueSnackbar(`Error processing transaction ${transaction.id} in batch ${batchKey}: ${error.message}`, { variant: 'error' });
                setIsLoadingBatchApprove(false);
                break; // Stop further processing
            }
        }

        // Check if there were any failed transactions
        if (failedTransactions.length > 0) {
            console.error(`Failed transactions for batch ${batchKey}:`, failedTransactions);
            enqueueSnackbar(
                `Batch ${batchKey} approval halted. ${failedTransactions.length} transaction(s) failed.`,
                { variant: 'error' }
            );
            setIsLoadingBatchApprove(false);
        } else {
            enqueueSnackbar(`Batch ${batchKey} approved successfully.`, { variant: 'success' });
            setIsLoadingBatchApprove(false);
            loadCropProfiles();
        }
    }, [groupedTransactionHistoryData, handleRowApprove]);


    const getBuySellPosition = (position) => {
        if (position == 1) return "Buy";
        if (position == 2) return "Sell";
        return "Unknown";
    };

    const handleToggleNoteVisibility = (batchKey, rowIndex) => {
        setGroupedTransactionHistoryData((prevData) => {
            const updatedBatch = prevData[batchKey].map((row, index) =>
                index === rowIndex ? { ...row, showNote: !row.showNote } : row
            );
            return {
                ...prevData,
                [batchKey]: updatedBatch,
            };
        });
    };

    const handleNoteChange = (e, batchKey, rowIndex, row) => {
        const { value } = e.target;

        if (filter?.filter_is_posted && value) {
            row.note = value;
            updateTransactionHistory(row);
        }
        if (value) {
            setGroupedTransactionHistoryData((prevData) => {
                const updatedBatch = prevData[batchKey].map((row, index) =>
                    index === rowIndex ? { ...row, note: value } : row
                );
                return {
                    ...prevData,
                    [batchKey]: updatedBatch,
                };
            });
        }
    };

    const updateTransactionHistory = async (row) => {
        try {
            formStatusActions.setIsFormDirty(true, true);
            const api = new ApiHelper({
                baseUrl: process.env.REACT_APP_DATANAC_USERS_API_URL_BASE,
                config: {},
                sessionManager: _sessionManager,
                // No caching:
                _instance: null
            });

            await api.callPost("marketing/UserTransactionHistory", row, globalState);
            await loadTransactionHistory();
            formStatusActions.setIsFormDirty(false, false);
            enqueueSnackbar("Your note was saved.", { preventDuplicate: true, variant: 'success' });
        } catch (err) {
            handleError(err);
        }
    }

    const handleNoteChangeWarning = () => {
        formStatusActions.setIsFormDirty(true, false);
    };

    // --- --- ---

    return (
        <>
            <WorkspaceHeader
                title='Transactions'
                breadCrumbs={[{
                    title: 'Broker',
                    icon: <FarmVaultIcon />
                }]}
            />
            <Stack direction="row" spacing={2} alignItems='center' className='headerWithMenuButton'>
                <AgentAutoComplete brokers={brokers} selectedBroker={selectedBroker} onChange={handleChangeAgentToken} />

                <Stack direction="row" columnGap={2} sx={{ pt: 1.5 }}>
                    <PopupState variant="popover" popupId="demo-popup-menu">
                        {(popupState) => (
                            <>
                                <Button variant="outlined" {...bindTrigger(popupState)}>
                                    {filter?.filter_is_posted ? "Posted" : "Unposted"} <ArrowDropDownOutlined />
                                </Button>
                                <Menu {...bindMenu(popupState)}>
                                    <MenuItem onClick={() => {
                                        filterHandleChangeState({ target: { name: 'filter_is_posted', value: false } });
                                        popupState.close();
                                    }}>
                                        Unposted
                                    </MenuItem>
                                    <MenuItem onClick={() => {
                                        filterHandleChangeState({ target: { name: 'filter_is_posted', value: true } });
                                        popupState.close();
                                    }}>
                                        Posted
                                    </MenuItem>
                                </Menu>
                                {formStatusComponent}
                            </>
                        )}
                    </PopupState>

                    <IconButton className='hiddenButton' size="large"
                        onClick={() => {
                            loadTransactionHistory();
                            loadCropProfiles();
                        }}
                    >
                        <Refresh size='large' />
                    </IconButton>
                </Stack>

                {(isLoadingBrokers || isLoadingCropProfiles || isLoadingTransactionHistory || isLoadingBatchApprove) && <CircularProgress />}
            </Stack>

            {Boolean(transactionHistoryTradeDates?.length) &&
                <>
                    <TableContainer >
                        {Object.keys(groupedTransactionHistoryData).map((batchKey) => {
                            const [batch, tradeDate] = batchKey.split("-");
                            return (
                                <div key={batchKey}>
                                    <Stack direction="row" flex sx={{ justifyContent: "space-between", alignItems: "center" }}>
                                        <Typography variant="h5" gutterBottom style={{ marginTop: "30px" }}>
                                            {tradeDate}
                                        </Typography>

                                        {!filter.filter_is_posted && (<Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => handleBatchApprove(batchKey)}
                                            style={{ marginLeft: "10px" }}
                                        >
                                            Approve
                                        </Button>
                                        )}
                                    </Stack>

                                    <Table stickyHeader aria-label="sticky table" className='marketing-table transaction-import-workspace-table'>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ fontWeight: "bold", backgroundColor: "#f5f5f5" }}>Client</TableCell>
                                                <TableCell sx={{ fontWeight: "bold", backgroundColor: "#f5f5f5" }}>Commodity</TableCell>
                                                <TableCell sx={{ fontWeight: "bold", backgroundColor: "#f5f5f5" }}>Position</TableCell>
                                                <TableCell sx={{ textAlign: "right", fontWeight: "bold", backgroundColor: "#f5f5f5" }}>Qty</TableCell>
                                                <TableCell sx={{ fontWeight: "bold", backgroundColor: "#f5f5f5" }} align="right">Price</TableCell>
                                                <TableCell sx={{ fontWeight: "bold", backgroundColor: "#f5f5f5" }}>Trace</TableCell>
                                                <TableCell sx={{ fontWeight: "bold", backgroundColor: "#f5f5f5" }}>Crop Profile</TableCell>
                                                <TableCell sx={{ textAlign: "right", fontWeight: "bold", backgroundColor: "#f5f5f5" }}>&nbsp;</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {groupedTransactionHistoryData[batchKey].map((row, index) => {
                                                if(row.note != null && row.note != ""){ row.showNote = true; }
                                                return (
                                                    <TableRow key={index}>
                                                        <TableCell>
                                                            <Box sx={{ pb: 1 }}>{formatProducerName(row)}</Box>
                                                            <Box>{row.account_name}</Box>
                                                        </TableCell>
                                                        <TableCell>{row?.commodity_name || row?.bloomberg_root || ""}</TableCell>
                                                        <TableCell>
                                                            <Box>
                                                                <span style={{ textTransform: "capitalize" }}>
                                                                    {`${getBuySellPosition(row.buy_sell_code)?.toLowerCase()} ${row.security_desc_line_1?.toLowerCase()}`}
                                                                </span>
                                                                <IconButton
                                                                    onClick={() => handleToggleNoteVisibility(batchKey, index)}
                                                                    size="small"
                                                                >
                                                                    <NoteAddIcon />
                                                                </IconButton>
                                                            </Box>
                                                            <Box>
                                                                {row.showNote && (
                                                                    <>
                                                                        <StatefulControlledTextField
                                                                            label=""
                                                                            placeholder='Notes'
                                                                            variant="outlined"
                                                                            size="small"
                                                                            inputProps={{ maxLength: 100 }}
                                                                            defaultValue={row.note || ""}
                                                                            InputLabelProps={{ shrink: false }}
                                                                            tabIndex={0}
                                                                            onBlur={handleNoteChangeWarning}
                                                                            onChange={(e) => handleNoteChange(e, batchKey, index, row)}
                                                                            fullWidth
                                                                        />
                                                                    </>
                                                                )}
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {formatNumber(((row?.quantity * row?.multiplication_factor) || 0), 0)}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {formatPrice(row.trade_price, 4)}
                                                        </TableCell>
                                                        <TableCell>
                                                            {row.tracer_number}
                                                        </TableCell>
                                                        <TableCell sx={{ p: "0px !important" }}>
                                                            <Box sx={{ mb: 1, width: "100%" }}>
                                                                <FormControl fullWidth>
                                                                    <Autocomplete
                                                                        name="crop_profile"
                                                                        options={getCropProfileLabelsByProducerToken(row?.producer_token, row?.commodity_name) || []}
                                                                        multiple={false}
                                                                        value={row?.crop_profile || null}
                                                                        disabled={row?.is_posted != null}
                                                                        onChange={(event, value) => {
                                                                            handleDispatchRowChange(row.id, 'crop_profile', value);
                                                                        }}
                                                                        autoSelect
                                                                        autoHighlight
                                                                        renderInput={(params) => (
                                                                            <TextField
                                                                                name="crop_profile"
                                                                                {...params}
                                                                                required
                                                                                onChange={null}
                                                                                InputLabelProps={{ shrink: true }}
                                                                            />
                                                                        )}
                                                                        renderOption={(props, option) => {
                                                                            const { key, ...optionProps } = props;
                                                                            return (
                                                                                <li key={key} {...optionProps}>
                                                                                    <Box>{option}</Box>
                                                                                </li>
                                                                            );
                                                                        }}
                                                                        PaperComponent={({ children }) => {
                                                                            return (
                                                                                <Paper>
                                                                                    {children}
                                                                                    <Button
                                                                                        color="primary"
                                                                                        variant="filled"
                                                                                        fullWidth
                                                                                        sx={{ justifyContent: "flex-start", pl: 2 }}
                                                                                        onMouseDown={() => {
                                                                                            setEditingCropProfile(getCropProfileFromTransactionHistory(row));
                                                                                        }}
                                                                                    >
                                                                                        + Add New
                                                                                    </Button>
                                                                                </Paper>
                                                                            );
                                                                        }}
                                                                    />
                                                                </FormControl>
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell sx={{ textAlign: "right" }}>
                                                            <Tooltip title="Discard" placement="top" arrow>
                                                                <span>
                                                                    <IconButton
                                                                        size="large"
                                                                        disabled={row?.is_posted != null}
                                                                        onClick={() => handleRowDelete(row)}>
                                                                        <Delete />
                                                                    </IconButton>
                                                                </span>
                                                            </Tooltip>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                    <Divider />
                                </div>
                            );
                        })}
                    </TableContainer></>
            }



            <Box className='cardContainer'>
                {!Boolean(transactionHistoryTradeDates?.length) && filter?.filter_is_posted &&
                    <EmptyCard
                        text="No transactions found"
                        secondaryText="No posted transactions were found."
                        isLoading={isLoadingTransactionHistory}
                    />
                }

                {!Boolean(transactionHistoryTradeDates?.length) && !filter?.filter_is_posted &&
                    <EmptyCard
                        text="No transactions found"
                        secondaryText="No pending transactions were found."
                        isLoading={isLoadingTransactionHistory}
                    />
                }
            </Box>

            <Dialog open={Boolean(editingCropProfile)}>
                <CropProfileEditDialog cropProfile={editingCropProfile} onClose={() => setEditingCropProfile(null)} onChange={handleChange_CropProfile} />
            </Dialog>

            {confirmDialogComponent}
        </>
    );
}