import { useDialog } from '@datanac/datanac-mui-toolkit'
import DeleteForeverOutlined from '@mui/icons-material/DeleteForeverOutlined'
import EastOutlined from '@mui/icons-material/EastOutlined'
import { Box, Card, CardActions, CardContent, CardHeader, Divider, IconButton, Stack, Typography, useMediaQuery } from '@mui/material'
import { AppContext } from 'App'
import { BudgetCountyPracticeIcon } from 'components/Icons/Icons'
import { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { formatNumber, formatPrice } from 'utility/formatters'
import { getUnitOfMeasureAbbreviation } from 'components/Budget/BudgetService'

export default function BudgetCountyCard({
    budgetCountyPractice,
    onChange
}) {

    const { globalState, globalDispatch, session } = useContext(AppContext);
    const { component: confirmDialogComponent, actions: confirmDialogActions } = useDialog();

    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'), { noSsr: true });

    const navigate = useNavigate();
    const { budget_id } = useParams();

    const [unitOfMeasureAbbreviation, setUnitOfMeasureAbbreviation] = useState(null);

    useEffect(() => {
        _getUnitOfMeasureAbbreviation(budgetCountyPractice);
    }, [budgetCountyPractice]);

    const _getUnitOfMeasureAbbreviation = async (countyPractice) => {
        const _unitOfMeasureAbbreviation = await getUnitOfMeasureAbbreviation(countyPractice) || "";
        setUnitOfMeasureAbbreviation(_unitOfMeasureAbbreviation);
    }

    const handleDeleteOnClick = () => {
        confirmDialogActions.confirm({
            title: "Delete this entry?",
            prompt: "Are you sure you want to delete this entry?",
            promptDetail: null,
            callback: () => {
                const _newState = { ...budgetCountyPractice };
                _newState.is_active = false;
                onChange && onChange(_newState);
            }
        })
    }

    const perAcreMultiplier = budgetCountyPractice?.reported_acreage == 0 ? 0 : 1 / budgetCountyPractice?.reported_acreage;

    return (
        <>
            <Card>
                <CardHeader
                    avatar={<BudgetCountyPracticeIcon />}
                    title={<>
                        {budgetCountyPractice?.commodity_name
                            + " " + (budgetCountyPractice?.practice_name && (" \u2013 " + budgetCountyPractice?.practice_name))
                            + " " + (budgetCountyPractice?.type_name && (budgetCountyPractice?.type_name != "No Type Specified" ? " \u2013 " + budgetCountyPractice?.type_name : ""))}
                    </>}
                    subheader={budgetCountyPractice?.county_name + ', ' + budgetCountyPractice?.state_name}
                    action={
                        <IconButton onClick={handleDeleteOnClick}>
                            <DeleteForeverOutlined sx={{ color: 'black' }} />
                        </IconButton>
                    }
                />

                <CardContent>
                    <Stack direction="column" spacing={1} height="100%">
                        <Stack direction='row' spacing={1} alignItems='center' justifyItems='center'>
                            <Box>
                                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                    <Typography variant='h6'>
                                        {formatPrice(
                                            (budgetCountyPractice?.revenue_simple - budgetCountyPractice?.production_cost) * perAcreMultiplier
                                            , 0)}
                                    </Typography>
                                    <Typography variant="body2" sx={{ ml: 0.5, fontSize: "small" }}>/ ac</Typography>
                                </Box>
                                <Typography variant='body1' sx={{ fontSize: '8pt', leading: '0px', lineHeight: '9pt', height: '100%' }}>
                                    NET INCOME
                                </Typography>
                            </Box>

                            <Divider orientation='vertical' flexItem />

                            <Box>
                                <Typography variant='h6'>
                                    {formatNumber(budgetCountyPractice?.reported_acreage, 0)}
                                </Typography>
                                <Typography variant='body1' sx={{ fontSize: '8pt', leading: '0px', lineHeight: '9pt', height: '100%' }}>
                                    ACRES
                                </Typography>
                            </Box>

                            <Divider orientation='vertical' flexItem />

                            <Box>
                                <Typography variant='h6'>
                                    {formatNumber(budgetCountyPractice?.yield, 0)}
                                </Typography>
                                <Typography variant='body1' sx={{ fontSize: '8pt', leading: '0px', lineHeight: '9pt', height: '100%' }}>
                                    YIELD
                                </Typography>
                            </Box>

                            {!isSmall && (
                                <>
                                    <Divider orientation='vertical' flexItem />

                                    <Box>
                                        <Box sx={{ display: "flex", alignItems: "baseline" }}>
                                            <Typography variant='h6'>
                                                {formatNumber((budgetCountyPractice?.yield * budgetCountyPractice?.reported_acreage), 0)}
                                            </Typography>
                                            <Typography variant="body2" sx={{ ml: 0.5, fontSize: "small", textTransform: "lowercase" }}>
                                                {unitOfMeasureAbbreviation}
                                            </Typography>
                                        </Box>
                                        <Typography variant='body1' sx={{ fontSize: '8pt', leading: '0px', lineHeight: '9pt', height: '100%' }}>
                                            PRODUCTION
                                        </Typography>
                                    </Box>
                                </>
                            )}
                        </Stack>
                    </Stack>

                </CardContent>

                <CardActions>
                    <IconButton onClick={() => { navigate(`/budget/${budget_id}/${budgetCountyPractice.id}`) }}>
                        <EastOutlined />
                    </IconButton>
                </CardActions>
            </Card>

            {confirmDialogComponent}
        </>
    )
}
