import { useDialog } from '@datanac/datanac-mui-toolkit'
import { ChatBubble, ExpandLess, ExpandMore } from '@mui/icons-material'
import { Alert, Box, Button, Chip, CircularProgress, Fade, IconButton, Link, Menu, MenuItem, Stack, Typography, useMediaQuery } from '@mui/material'
import {
    Aggregate,
    AggregateColumnDirective,
    AggregateColumnsDirective,
    AggregateDirective,
    AggregatesDirective,
    ColumnDirective,
    ColumnsDirective,
    ExcelExport,
    Filter,
    GridComponent,
    Group,
    Inject,
    Page,
    PdfExport,
    Sort,
    Toolbar,
} from '@syncfusion/ej2-react-grids'
import { ChatPopupHelper, SYSTEM_PROMPT_RAG } from 'components/Financial/schedule_financial_statement/ChatPopupHelper'
import { getUniqueProducerFarmCrops } from 'components/Vault/Farm/helpers'
import { WORKSPACE_VIEW_CARD, WORKSPACE_VIEW_TABLE } from 'components/Workspaces/helpers'
import { useCallback, useContext, useEffect, useReducer, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'
import { formatNumber } from 'utility/formatters'
import { getBudgetYears } from 'components/Budget/helpers';
import { AppContext } from 'App';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import ArrowDropDownOutlined from '@mui/icons-material/ArrowDropDownOutlined';

export const ReportViewer = ({
    columnsDirective,
    counties,
    producerFarmFieldCrops,
    isLoadingCounties,
    reportType,
    setIsDetailViewOnFunc,
    currentYear,
    setCurrentYear
}) => {
    const navigate = useNavigate();

    const location = useLocation();

    const { globalState, globalDispatch } = useContext(AppContext);

    // ---

    const [filterLocationStateName, setFilterLocationStateName] = useState(null);
    const [filterLocationCountyName, setFilterLocationCountyName] = useState(null);

    // ---

    const captionTemplate = (props) => {
        let groupLabel = props?.field;
        let groupValue = "";
        let groupCount = 0;
        if (props?.items.records?.length && props?.items.records[0] && props?.items.records[0][props?.field]) {
            groupValue = props?.items.records[0][props?.field]
        } else if (props?.items?.length && props.items[0]) {
            groupValue = props?.items[0][props?.field];
        }

        return (
            <span className="groupItems">
                <label onClick={() => setFilter(groupLabel, groupValue)}>
                    <strong>{groupValue}</strong> ({groupCount})
                </label>
            </span>
        );
    }

    const [isDetailViewOn, setIsDetailViewOn] = useState(false);

    const setFilter = (groupLabel, groupValue) => {
        if(groupLabel == 'location_state_name') {
            setFilterLocationStateName(groupValue);
            setFilterLocationCountyName(null);
        } 
        else if(groupLabel == 'location_county_name') {
            setFilterLocationCountyName(groupValue);
            setFilterLocationStateName(null);
        }
    }

    useEffect(() => {
        if(filterLocationStateName || filterLocationCountyName) {
            setIsDetailViewFun(false);
        }
    }, [filterLocationStateName, filterLocationCountyName])

    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'), { noSsr: true });
    const { component: confirmDialogComponent, actions: confirmDialogActions } = useDialog();

    const [workspaceMode, setWorkspaceMode] = useState(isSmall ? WORKSPACE_VIEW_CARD : WORKSPACE_VIEW_TABLE);
    let _reportGrid;

    const [allProducerFarmCrops, setAllProducerFarmCrops] = useState([]);

    useEffect(() => {
        if(reportType == 'farm') {
            // Reducer counties[ii].ProducerFarmCrops
            var _producerFarmCrops = producerFarmFieldCrops?.filter((pfc) => {
                const isCountyMatch = filterLocationCountyName == null || pfc?.location_county_name == filterLocationCountyName;
                const isStateMatch = filterLocationStateName == null || pfc?.location_state_name == filterLocationStateName;
                return isCountyMatch && isStateMatch;
            });
            
            const _uniqueProducerFarmCrops = getUniqueProducerFarmCrops(_producerFarmCrops);
            setAllProducerFarmCrops(_uniqueProducerFarmCrops)
            // setAllProducerFarmCrops(producerFarmCrops);
        }
        else if(reportType == 'field') {
            // Reducer counties[ii].ProducerFarmCrops
            var producerFarmCrops = [];
            counties
                ?.filter((county) => {
                    const isCountyMatch = filterLocationCountyName == null || county?.location_county_name == filterLocationCountyName;
                    const isStateMatch = filterLocationStateName == null || county?.location_state_name == filterLocationStateName;
                    return isCountyMatch && isStateMatch;
                })
                ?.forEach((county) => {
                    county?.ProducerFarms?.forEach((producerFarm) => {
                        producerFarm?.ProducerFarmFieldCrops?.forEach((producerFarmFieldCrop) => {
                            producerFarmCrops.push({
                                ...producerFarmFieldCrop,
                                CustomerName: producerFarm?.customer_name,
                                OrderDate: producerFarm?.order_date,
                                Freight: producerFarm?.freight,
                                ShippedDate: producerFarm?.shipped_date,
                            });
                        });
                    });
                });
            setAllProducerFarmCrops(producerFarmCrops);
        }
    }, [producerFarmFieldCrops, filterLocationStateName, filterLocationCountyName, reportType]);

    // Template for group footer aggregate
    const groupFooterAverageTemplate = (props) => {
        return <span>Avg: <strong>{formatNumber(props.Average, 0)}</strong></span>;
    };

    const handleGridToolbarClick = async (args) => {
        if (_reportGrid && args?.item?.id?.toLowerCase().includes("excel")) {
            _reportGrid.excelExport();
        } else if (_reportGrid && args?.item?.id?.toLowerCase().includes("pdf")) {
            _reportGrid.pdfExport();
        }
    }

    const pageOptionsSummary = {
        pageSize: 20,
        pageSizes: [5, 10, 15, 20, 50, 100, 200, 1000]
    };
    const pageOptionsDetail = {
        pageSize: 20,
        pageSizes: [5, 10, 15, 20, 50, 100, 200, 1000]
    };

    // ---

    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition();

    if (!browserSupportsSpeechRecognition) {
        alert("Browser doesn't support speech recognition");
    }

    const handleStartRecording = async () => {
        resetTranscript();
        setChatReponse(null);

        // https://www.npmjs.com/package/react-speech-recognition#basic-example
        SpeechRecognition.startListening({ continuous: true });
    }

    const handleStopRecording = async () => {
        // https://www.npmjs.com/package/react-speech-recognition#basic-example
        SpeechRecognition.stopListening();
    }

    const [chatReponse, setChatReponse] = useState(null);
    const handleChatCompletion = async (_text) => {
        if (_text) {
            setChatReponse(_text);
        }
    }

    const [systemPromptRag, setSystemPromptRag] = useState(SYSTEM_PROMPT_RAG);
    useEffect(() => {
        setSystemPromptRag(SYSTEM_PROMPT_RAG + "\n ```json" + JSON.stringify(allProducerFarmCrops) + '\n```');
    }, [allProducerFarmCrops])

    const setIsDetailViewFun = (val) => {
        setIsDetailViewOn(val);
        setIsDetailViewOnFunc(val);
    }

    return (
        <>

            <Stack direction="row" spacing={2}>
                <Stack>
                    

                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', mb: 2, gap: 2 }}>
                        <Button onClick={() => setIsDetailViewFun(!isDetailViewOn)} variant='text'
                            endIcon={isDetailViewOn ? <ExpandLess /> : <ExpandMore />}>
                            Detail
                        </Button>

                        <PopupState variant="popover" popupId="demo-popup-menu">
                            {popupState => (
                                <>
                                    <Button variant="outlined" {...bindTrigger(popupState)} className='year-filter'>
                                        {currentYear} <ArrowDropDownOutlined />
                                    </Button>
                                    <Menu {...bindMenu(popupState)}>
                                        {getBudgetYears()?.map((year, index) => (
                                            <MenuItem key={index} onClick={() => {
                                                setCurrentYear(year);
                                                popupState.close();
                                            }}>
                                                {year}
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </>
                            )}
                        </PopupState>

                        {filterLocationStateName && <Chip label={filterLocationStateName} onDelete={() => setFilterLocationStateName(null)} />}
                        {filterLocationCountyName && <Chip label={filterLocationCountyName} onDelete={() => setFilterLocationCountyName(null)} />}
                    </Box>
                </Stack>

                <Stack>
                    <IconButton disabled={!Boolean(counties?.length) ? true : false} onClick={() => {
                        if (listening) {
                            handleStopRecording();
                        } else {
                            handleStartRecording();
                        }
                    }}
                        sx={{ color: "blueviolet" }}>
                        {listening ? <CircularProgress size="1rem" /> : <ChatBubble />}
                    </IconButton>

                </Stack>
            </Stack>

            {!listening && transcript && !chatReponse && <Box sx={{ mb: 2 }}>
                <ChatPopupHelper onChatCompletion={handleChatCompletion} userMessage={transcript}
                    systemPrompt={systemPromptRag}
                />
            </Box>}
            {chatReponse && <Alert severity="info" sx={{ mb: 2 }}>
                <Typography variant="body1" sx={{ fontFamily: "Georgia", fontWeight: "normal", whiteSpace: "pre-wrap" }}>
                    {chatReponse}
                </Typography>
            </Alert>}

            {isLoadingCounties && <CircularProgress />}
                
            {!isLoadingCounties && counties?.length && Number(counties?.length) && <GridComponent
                cssClass={'tilley ' + (isDetailViewOn ? 'tilley-grid-detail' : 'tilley-grid-summary')}
                visible={!isLoadingCounties && Number(counties?.length)}
                style={{ color: "#8CC63F" }}
                dataSource={allProducerFarmCrops}
                allowSorting={true}
                allowFiltering={isDetailViewOn}
                allowGrouping={true}
                allowPaging={true}
                pageSettings={isDetailViewOn ? pageOptionsDetail : pageOptionsSummary}
                toolbar={["ExcelExport", "PdfExport"]}
                toolbarClick={handleGridToolbarClick}
                allowPdfExport={true}
                allowExcelExport={true}
                groupSettings={{
                    showDropArea: isDetailViewOn,
                    columns: isDetailViewOn ? ['location_state_name', 'location_county_name'] : [],
                    // Header:
                    captionTemplate: captionTemplate,
                }}
                ref={g => _reportGrid = g}
            >
                <ColumnsDirective>
                    {columnsDirective?.map((item, index) =>
                        <ColumnDirective field={item?.field} headerText={item?.headerText} width={item?.width} textAlign={item?.textAlign} visible={item?.visible} key={index} />
                    )}
                </ColumnsDirective>
                <AggregatesDirective>
                    <AggregateDirective>
                        <AggregateColumnsDirective>
                            <AggregateColumnDirective
                                field="approved_yield"
                                type="Average"
                                groupFooterTemplate={isDetailViewOn ? groupFooterAverageTemplate : "<span/>"}
                            />
                        </AggregateColumnsDirective>
                    </AggregateDirective>
                </AggregatesDirective>
                <Inject services={[Sort, Filter, Group, Aggregate, Page, Toolbar, ExcelExport, PdfExport]} />
            </GridComponent>}

            {confirmDialogComponent}
        </>
    )
}