import { Box, Button, DialogActions, DialogContent, DialogTitle, FormControl, Grid, Stack, TextField, useMediaQuery } from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { _sessionManager, ApiHelper, UsersApiHelper } from "api/ApiHelper";
import { AppContext } from "App";
import { BUDGET_STATUS_ACTIVE } from "components/Budget/helpers";
import { MASK_NUMERIC, StatefulControlledTextField } from "components/Core/StatefulControlledTextField";
import dayjs from 'dayjs';
import { useFormStatus } from "mui-toolkit/useFormStatus";
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useMemo, useState } from "react";
import { v4 } from "uuid";
import { ApiHelper as ToolkitApiHelper } from "@datanac/datanac-api-toolkit/dist/api-helper";
import DataFormSelect from "components/Core/DataFormSelect";
import { getCurrentReinsuranceYear } from "@datanac/datanac-api-toolkit/dist/utility/InsurancePlanHelper";
import { POSITION_CATEGORIES, SECURITY_TYPE_FUTURES, SECURITY_TYPES } from "components/Marketing/PositionSummaryReportPane";
import { BUY_SELL_CODE_BUY, BUY_SELL_CODE_SELL } from "./useMarketingReport";

// ---
export const FuturesOptionsEditDialog = ({
    producer_token,
    onChange,
    onClose,
    futureOptions
}) => {
    const { globalState } = useContext(AppContext);
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'), { noSsr: true });
    const { component: formStatusComponent, actions: formStatusActions, state: formStatusState } = useFormStatus();
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        ...futureOptions,
    });

    // ---

    const onSubmit = (e) => {
        if (!validateForm()) {
            return;
        }

        if (!state?.id) {
            const _newState = {
                ...state,
                id: v4(),
                producer_token: producer_token,
                is_active: true,
                buy_sell_code: state?.position?.toLowerCase() == "buy" ? BUY_SELL_CODE_BUY : BUY_SELL_CODE_SELL,
                trade_date: state.trade_date || new Date(),
            };
            setState(_newState);
            onChange && onChange(_newState);
        }
        onClose && onClose();
    }

    useEffect(() => {
        setState(futureOptions);
    }, [futureOptions]);

    const handleError = (err) => {
        console.warn(err);
        enqueueSnackbar(err.detail || err.message || JSON.stringify(err), { preventDuplicate: true, variant: 'error' });
    }

    // ---

    const [futuresSymbols, setFuturesSymbols] = useState([]);
    const [isLoadingFuturesSymbols, setIsLoadingFuturesSymbols] = useState(false);

    const selectedFuturesSymbol = useMemo(() =>
        futuresSymbols?.find(s => s?.symbol == state?.symbol) || null,
        [futuresSymbols, state?.symbol]);

    useEffect(() => {
        const _loadFuturesSymbols = async () => {
            try {
                const _currentYear = state?.crop_year;
                const _currentMonth = dayjs().month() + 1;

                const _symbolFilter = {
                    commodity_name: state?.commodity_name
                };
                const _futuresSymbols = await ApiHelper.callSmociObject("data/DATANAC", "Markets", "FuturesSymbol", "Search", _symbolFilter, globalState)


                const _orderedSymbols = _futuresSymbols
                    .filter(symbol =>
                        // Future year Or current year, not expired:
                        symbol?.year >= _currentYear - 3
                    )
                    .map(symbol => ({
                        ...symbol,
                        is_current_year: symbol?.year == _currentYear && symbol?.month_number >= _currentMonth,
                        is_expired: (symbol?.year < _currentYear || (symbol?.year == _currentYear && symbol?.month_number < _currentMonth)),
                    }));
                _orderedSymbols
                    .sort((a, b) => {
                        if (a?.is_expired && !b?.is_expired) {
                            return 1;
                        }
                        if (!a?.is_expired && b?.is_expired) {
                            return -1;
                        }

                        if (a?.is_current_year && !b?.is_current_year) {
                            return -1;
                        }
                        if (!a?.is_current_year && b?.is_current_year) {
                            return 1;
                        }
                        if (a?.is_current_year && b?.is_current_year) {
                            return a?.month_number - b?.month_number;
                        }
                        return a?.year - b?.year;
                    });

                const _autocompleteSymbols = _orderedSymbols.map(symbol => ({
                    label: symbol?.symbol + " - " + symbol?.month_name + " " + symbol?.year + " " + symbol?.commodity_name,
                    id: symbol?.symbol,
                    symbol: symbol?.symbol,
                    month_name: symbol?.month_name,
                    year: symbol?.year,
                    commodity_name: symbol?.commodity_name,
                }));

                setFuturesSymbols(_autocompleteSymbols);
            } catch (err) {
                handleError(err);
                setFuturesSymbols([]);
            }
        };

        if (state?.commodity_name) {
            _loadFuturesSymbols();
        }
    }, [globalState?.producer_token, globalState?.agent_token, state?.commodity_name])

    // ---

    const handleChangeStateSelect = (event, value) => {
        if (value == null && event.target.value != null) {
            value = event.target.value
        }

        formStatusActions.setIsFormDirty(true, false);

        if (state[event?.target?.name] != value) {
            formStatusActions.setIsFormDirty(true, false);

            const _newState = { ...state };
            _newState[event?.target?.name] = value;

            setState(_newState);
        }
    }

    const handleChangeState = async (event, value) => {
        if (!value && event.target.value) {
            value = event.target.value
        }

        if (state[event?.target?.name] != value) {
            formStatusActions.setIsFormDirty(true, false);

            const _newState = { ...state };
            _newState[event?.target?.name] = value;
            setState(_newState);
        }
    }

    // ---

    const formMaxWidthShort = '800px';


    const validateForm = () => {
        const validationRules = [
            { field: 'position', message: 'Position is required.' },
            { field: 'trade_date', message: 'Date is required.' },
            { field: 'quantity', message: 'Qty is required.' },
            { field: 'commodity_name', message: 'Commodity is required.' },
            { field: 'security_type', message: 'Security Type is required.' },
            { field: 'symbol', message: 'Futures Symbol is required.' },
            { field: 'trade_price', message: 'Price is required.' },
            { field: 'tracer_number', message: 'Tracer is required.' }
        ];

        for (const rule of validationRules) {
            if (!state?.[rule.field] && (rule.condition === undefined || rule.condition)) {
                enqueueSnackbar(rule.message, { variant: 'error' });
                return false;
            }
        }
        return true;
    };

    return (
        <>
            <DialogTitle>Add Futures/Options</DialogTitle>
            <DialogContent>
                <Box sx={{ width: '100%', maxWidth: formMaxWidthShort }}>
                    <Grid container spacing={2}>

                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    value={state?.position || null}
                                    onChange={(event, newValue) => {
                                        handleChangeStateSelect({ target: { name: 'position', value: newValue } });
                                    }}
                                    autoSelect
                                    autoHighlight
                                    options={POSITION_CATEGORIES}
                                    getOptionLabel={(option) => option || ""}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Position"
                                            name="position"
                                            required
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth className="trade_date">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        className="trade_date_picker"
                                        name="trade_date"
                                        label="Date *"
                                        format="MM/DD/YYYY"
                                        required
                                        value={state?.trade_date ? dayjs(state?.trade_date) : null}
                                        onChange={(val) => handleChangeState({ target: { name: 'trade_date', value: val } })}
                                        slots={{
                                            textField: TextField,
                                        }}
                                        slotProps={{
                                            textField: {
                                                fullWidth: true,
                                                onKeyDown: (e) => {
                                                    if (e.key === 'Tab') {
                                                        e.stopPropagation(); // Prevent focus from moving to the calendar icon
                                                    }
                                                },
                                            },
                                            openPickerButton: {
                                                tabIndex: -1, // Skip the calendar icon in the tab order
                                            },
                                        }}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <StatefulControlledTextField
                                    label="Qty (# Contracts)"
                                    name="quantity"
                                    required
                                    value={state?.quantity || ''}
                                    onChange={handleChangeState}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 500 }} // Limit to 500 characters
                                    maskType={MASK_NUMERIC}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <DataFormSelect
                                value={state?.commodity_name || ''}
                                name="commodity_name"
                                isRequired={true}
                                onChange={handleChangeStateSelect}
                                objectName="Commodity"
                                itemFilter={{ reinsurance_year: getCurrentReinsuranceYear() }}
                                itemGetKey={(item) => item.commodity_name}
                                itemGetLabel={(item) => item.commodity_name}
                                doFetch={true}
                                isLoading={false}
                                multiple={false}
                                limitTags={isSmall ? 2 : 3}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} key={name}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    value={state?.security_type || null}
                                    onChange={(event, newValue) => {
                                        handleChangeStateSelect({ target: { name: 'security_type', value: newValue } });
                                    }}
                                    autoSelect
                                    autoHighlight
                                    options={SECURITY_TYPES}
                                    getOptionLabel={(option) => option || ""}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Security Type"
                                            name="security_type"
                                            required
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    name="symbol"
                                    options={futuresSymbols || []}
                                    multiple={false}
                                    value={selectedFuturesSymbol || null}
                                    getOptionLabel={(option) => option?.label || ""}
                                    onChange={(event, value) => {
                                        handleChangeState({ target: { name: 'symbol', value: value?.symbol } });
                                    }}
                                    autoSelect
                                    autoHighlight
                                    renderInput={(params) => (
                                        <TextField
                                            name="symbol"
                                            {...params}
                                            label="Futures Symbol"
                                            required
                                            onChange={handleChangeState}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    )}
                                    renderOption={(props, option) => {
                                        const { key, ...optionProps } = props;
                                        return (
                                            <li key={key} {...optionProps}>
                                                <Stack direction="row" spacing={1} key={key} sx={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                                                    <Box sx={{ textAlign: "center", width: "60pt" }}>{option?.symbol}</Box>
                                                    <Box sx={{ alignContent: "center" }}>-</Box>
                                                    <Box sx={{ textAlign: "center", width: "60pt" }}>{option?.month_name?.toUpperCase()} {(String(option?.year) + "  ")?.substring(2, 4)}</Box>
                                                    <Box sx={{ alignContent: "center" }}>-</Box>
                                                    <Box sx={{ alignContent: "center" }}>{option?.commodity_name}</Box>
                                                </Stack>
                                            </li>
                                        );
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <StatefulControlledTextField
                                    label={'Price *'}
                                    name={'trade_price'}
                                    required
                                    defaultValue={state?.trade_price || ""}
                                    onChange={handleChangeState}
                                    InputLabelProps={{ shrink: true }}
                                    maskType={MASK_NUMERIC}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <StatefulControlledTextField
                                    label={'Tracer'}
                                    name={'tracer_number'}
                                    required={false}
                                    defaultValue={state?.tracer_number || ""}
                                    onChange={handleChangeState}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>

            <DialogActions>
                <Stack direction="row-reverse" spacing={1} useFlexGap flexWrap="wrap" justifyContent='flex-end'>
                    <Button variant='contained' onClick={onSubmit}>OK</Button>
                    <Button variant='contained' color='secondary' autoFocus onClick={onClose}>Cancel</Button>
                </Stack>
            </DialogActions>
        </>
    );
};