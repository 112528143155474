import { useCardController } from '@datanac/datanac-mui-toolkit'
import { AppContext } from 'App'
import { ApiHelper, UsersApiHelper } from 'api/ApiHelper'
import _ from 'lodash'
import { useContext, useEffect, useState } from 'react'
import { formatPracticeName } from 'utility/formatters'
import { getUniqueCounties } from './helpers'
import { _getUniqueCountyPractices } from '@datanac/datanac-api-toolkit/dist/utility/ProducerFarmHelper'

export const useFarmController = ({
    crop_year,

    filter,

    onError,

    doLoadCIMS = false,
    doLoadProducerFarmFieldCropEvents = false,
}) => {
    const { globalState } = useContext(AppContext);

    const [isLoading, setIsLoading] = useState(true);

    // ---

    // Load (1) Crops/Fields (2) Farms (3) CountyPractices

    const [isLoadingProducerFarmFieldCrops, setIsLoadingProducerFarmFieldCrops] = useState(null);
    const [producerFarmFieldCrops, setProducerFarmFieldCrops] = useState(null);
    const loadProducerFarmFieldCrop = async () => {
        try {
            const producerFilter = {
                producer_token: globalState.producer_token,
                crop_year,
                is_active: true
            };
            const crops = await UsersApiHelper.selectProducerFarmFieldCrops(producerFilter, globalState);

            if (crops?.length) {
                const _producerFarmFieldCrops = crops
                    ?.filter(c => (
                        Boolean(!filter?.commodity_name?.length)
                        || filter?.commodity_name?.includes(c.commodity_name)
                    ))
                    ?.filter(c => (
                        Boolean(!filter?.practice_name?.length)
                        || filter?.practice_name?.includes(c.practice_name)
                    ))
                    ?.sort((a, b) =>
                        (a?.fsa_farm_number || 0) - (b?.fsa_farm_number || 0)
                        || (a?.fsa_tract_number || 0) - (b?.fsa_tract_number || 0)
                        || a.fsa_field_number?.localeCompare(b.fsa_field_number)
                    )
                    ?.sort((a, b) =>
                        a.commodity_name?.localeCompare(b.commodity_name)
                        || a.type_name?.localeCompare(b.type_name)
                        || a.practice_name?.localeCompare(b.typpractice_namee_name)
                    );
                setProducerFarmFieldCrops(_producerFarmFieldCrops);
            } else {
                setProducerFarmFieldCrops(null);
            }

            setIsLoadingProducerFarmFieldCrops(false);
            setIsLoadingCounties(false);
        } catch (err) {
            setIsLoading(false);
            setIsLoadingCounties(false);
            setIsLoadingProducerFarmFieldCrops(false);
            setProducerFarmFieldCrops(null);
            console.warn(err);
            onError && onError(err);
        }
    }

    useEffect(() => {
        if (globalState?.producer_token) {
            loadProducerFarmFieldCrop();
        }
    }, [globalState?.producer_token, crop_year, filter]);

    // ---

    const [producerFarms, setProducerFarms] = useState(null);
    const loadProducerFarms = async () => {
        try {
            const producerFilter = {
                producer_token: globalState.producer_token,
                crop_year,
                is_active: true
            };
            const farms = await UsersApiHelper.selectProducerFarms(producerFilter, globalState);
            if (farms?.length) {
                if (producerFarmFieldCrops?.length) {
                    farms.forEach(f => {
                        f.ProducerFarmFieldCrops = producerFarmFieldCrops
                            ?.filter(crop =>
                                crop.producer_farm_id == f.id
                            )
                            ?.sort((a, b) =>
                                a.commodity_name?.localeCompare(b.commodity_name)
                                || a.type_name?.localeCompare(b.type_name)
                                || a.practice_name?.localeCompare(b.typpractice_namee_name)
                            );
                    })
                }

                setProducerFarms(farms);
            } else {
                setProducerFarms([]);
            }

            setIsLoading(false);
        } catch (err) {
            setProducerFarms(null);
            setIsLoading(false);
            console.warn(err);
            onError && onError(err);
        }
    }

    useEffect(() => {
        if (globalState?.producer_token && crop_year && producerFarmFieldCrops?.length) {
            loadProducerFarms();
        } else {
            setProducerFarms(null);
        }
    }, [globalState?.producer_token, crop_year, producerFarmFieldCrops]);

    // ---

    const [isLoadingCounties, setIsLoadingCounties] = useState(true);
    const [counties, setCounties] = useState(null);

    const loadCounties = async () => {
        try {
            if (producerFarms?.length) {
                const _counties = getUniqueCounties(producerFarms);

                if (_counties?.length) {
                    _counties.forEach(cp => {
                        cp.ProducerFarms = producerFarms
                            ?.filter(f =>
                                f.location_state_name == cp.location_state_name
                                && f.location_county_name == cp.location_county_name
                            )
                            ?.sort((a, b) =>
                                (a?.fsa_farm_number || 0) - (b?.fsa_farm_number || 0)
                            );
                    });

                    setCounties(_counties);
                } else {
                    setCounties(null);
                }
            } else {
                setCounties(null);
            }

            setIsLoadingCounties(false);
        } catch (err) {
            setCounties(null);
            setIsLoadingCounties(false);
            console.warn(err);
            onError && onError(err);
        }
    }

    useEffect(() => {
        if (globalState?.producer_token && crop_year && producerFarms?.length) {
            loadCounties();
        } else {
            setCounties(null);
        }
    }, [globalState?.producer_token, crop_year, producerFarms]);

    // ---

    const [isLoadingCountyPractices, setIsLoadingCountyPractices] = useState(true);
    const [countyPractices, setCountyPractices] = useState(null);

    const loadCountyPractices = async () => {
        try {
            if (producerFarms?.length) {
                const _allCountyPractices = [];
                producerFarms?.forEach(currentFarm => {
                    currentFarm.ProducerFarmFieldCrops.forEach(currentCrop => {
                        _allCountyPractices.push({
                            ...currentFarm,
                            ...currentCrop
                        })
                    })
                })
                const countyPractices = _getUniqueCountyPractices(_allCountyPractices);

                setCountyPractices(countyPractices);
            } else {
                setCountyPractices(null);
            }

            setIsLoadingCountyPractices(false);
        } catch (err) {
            setCountyPractices(null);
            setIsLoadingCountyPractices(false);
            console.warn(err);
            onError && onError(err);
        }
    }

    useEffect(() => {
        if (globalState?.producer_token && crop_year && producerFarms?.length) {
            loadCountyPractices();
        } else {
            setCountyPractices(null);
        }
    }, [globalState?.producer_token, crop_year, producerFarms]);

    // ---

    const [isLoadingCimsGeometry, setIsLoadingCimsGeometry] = useState(doLoadCIMS);
    const [cimsGeometry, setCimsGeometry] = useState([]);
    useEffect(() => {
        if (doLoadCIMS && globalState.producer_token) {
            const loadCIMS = async () => {
                try {
                    const _cimsFilter = {
                        producer_token: globalState.producer_token + " ",
                    };
                    var _maps = await UsersApiHelper.users_selectObject("ProducerFarmFieldMap", _cimsFilter, globalState)
                    _maps.forEach(geom => {
                        if (geom?.geometry) {
                            geom.geometry_raw = geom.geometry;
                            geom.geometry = JSON.parse(geom.geometry);
                        }
                    });
                    
                    var _cimsGeometry = await ApiHelper.callSmociObject("data/DATANAC", "FSA", "CIMS_Geometry", "Search", _cimsFilter, globalState)
                    _cimsGeometry.forEach(geom => {
                        if (geom?.geometry) {
                            geom.geometry_raw = geom.geometry;
                            geom.geometry = JSON.parse(geom.geometry);
                        }
                    });

                    const _combinedMaps = [..._maps, ..._cimsGeometry];

                    setCimsGeometry(_combinedMaps);
                    setIsLoadingCimsGeometry(false);
                } catch (err) {
                    setCimsGeometry([]);
                    setIsLoadingCimsGeometry(false);
                    console.warn(err);
                    onError && onError(err);
                }
            }

            loadCIMS();
        }
    }, [doLoadCIMS, globalState.producer_token]);

    // ---

    const [isLoadingProducerFarmFieldCropEvents, setIsLoadingProducerFarmFieldCropEvents] = useState(doLoadProducerFarmFieldCropEvents);
    const [producerFarmFieldCropEvents, setProducerFarmFieldCropEvents] = useState([]);
    const _loadProducerFarmFieldCropEvents = async () => {
        try {
            const _pfcFilter = {
                ...filter,
                crop_year: crop_year,
            }
            var _ProducerFarmFieldCropEvents = await UsersApiHelper.users_selectObject("ProducerFarmFieldCropEvent", _pfcFilter, globalState);

            // Sort by createdate descending
            var _ProducerFarmFieldCropEvents = _ProducerFarmFieldCropEvents?.sort((a, b) => {
                return new Date(b.createdate) - new Date(a.createdate);
            });

            // Join _ProducerFarmFieldCropEvents with producerFarmFieldCrops
            if (_ProducerFarmFieldCropEvents?.length) {
                _ProducerFarmFieldCropEvents.forEach(event => {
                    event.ProducerFarmFieldCrop = producerFarmFieldCrops?.find(crop =>
                        crop.id == event.producer_farm_field_crop_id
                    );
                });
            }

            setProducerFarmFieldCropEvents(_ProducerFarmFieldCropEvents);
            setIsLoadingProducerFarmFieldCropEvents(false);
        } catch (err) {
            setProducerFarmFieldCropEvents([]);
            setIsLoadingProducerFarmFieldCropEvents(false);
            console.warn(err);
            onError && onError(err);
        }
    }
    useEffect(() => {
        if (doLoadProducerFarmFieldCropEvents
            && globalState.producer_token
            && producerFarmFieldCrops?.length
        ) {
            _loadProducerFarmFieldCropEvents();
        }
    }, [doLoadProducerFarmFieldCropEvents, globalState.producer_token, producerFarmFieldCrops]);

    // ---

    return ({
        counties,
        countyPractices,

        producerFarms,

        isLoadingProducerFarmFieldCrops,
        producerFarmFieldCrops,

        isLoadingCimsGeometry,
        cimsGeometry,

        isLoadingProducerFarmFieldCropEvents,
        producerFarmFieldCropEvents,
        _loadProducerFarmFieldCropEvents,

        isLoading,
        isLoadingCounties
    });
}