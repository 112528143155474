import { ApiHelper, USER_ROLE_ADMIN } from '@datanac/datanac-api-toolkit/dist/api-helper';
import { getCurrentReinsuranceYear } from '@datanac/datanac-api-toolkit/dist/utility/InsurancePlanHelper';
import { useDialog } from '@datanac/datanac-mui-toolkit/dist/hooks';
import { AddCircleOutline, DeleteForeverOutlined } from '@mui/icons-material';
import ArrowDropDownOutlined from '@mui/icons-material/ArrowDropDownOutlined';
import EastOutlinedIcon from '@mui/icons-material/EastOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, Button, Dialog, Divider, FormControl, Grid, IconButton, Menu, MenuItem, Paper, Stack, TextField, Typography, useMediaQuery } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { AppContext } from 'App';
import { UsersApiHelper, _sessionManager } from 'api/ApiHelper';
import { CropProfileEditDialog } from 'components/Broker/CropProfileEditDialog';
import { getUnitOfMeasureAbbreviation } from 'components/Budget/BudgetService';
import { BUDGET_STATUS_ACTIVE, getBudgetYears } from 'components/Budget/helpers';
import DataFormSelect from 'components/Core/DataFormSelect';
import { StatefulControlledTextField } from 'components/Core/StatefulControlledTextField';
import { propUpdateReducer } from 'components/Core/propUpdateReducer';
import { FarmVaultIcon } from 'components/Icons/Icons';
import { getUniqueCropPractices } from 'components/Vault/Farm/helpers';
import WorkspaceActionBar from 'components/Workspaces/WorkspaceActionBar';
import WorkspaceHeader from 'components/Workspaces/WorkspaceHeader';
import TilleyDataGrid from 'components/shared/DataGrid/TilleyDataGrid';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { useSnackbar } from 'notistack';
import React, { Fragment, useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { useParams } from 'react-router-dom';
import { formatDateContext, formatNumber, formatNumberFinancial, formatPercentage, formatPrice, formatProducerName } from 'utility/formatters';
import { v4 } from 'uuid';
import { FuturesOptionsEditDialog } from './FuturesOptionsEditDialog';
import { SalesEditDialog } from './SalesEditDialog';
import { SALE_CATEGORY_BASIS, SALE_CATEGORY_CASH, SALE_CATEGORY_HTA, SALE_CATEGORY_NO_PRICE, formatDeliveryPeriod } from './SalesHelper';
import { BUY_SELL_CODE_BUY } from './useMarketingReport';

export const POSITION_CATEGORIES = ['Buy', 'Sell'];
export const SECURITY_TYPE_FUTURES = 'Futures';
export const SECURITY_TYPE_OPTIONS = 'Options';
export const SECURITY_TYPE_SPECIAL_OPTIONS = 'Special Options';
export const SECURITY_TYPES = [SECURITY_TYPE_FUTURES, SECURITY_TYPE_OPTIONS, SECURITY_TYPE_SPECIAL_OPTIONS];

export default function PositionSummaryReportPane() {

    const { globalState, globalDispatch } = useContext(AppContext);
    const [futures, setFutures] = useState(null);

    const {
        producer_token,
        defaultYear,
        default_commodity_name
    } = useParams();

    const { enqueueSnackbar } = useSnackbar();
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'), { noSsr: true });

    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('lg'), { noSsr: true });

    const { component: confirmDialogComponent, actions: confirmDialogActions } = useDialog();

    // --- 

    const [isLoadingCurrent, setIsLoadingCurrent] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState(null);

    const [currentYear, setCurrentYear] = useState(defaultYear ? defaultYear : getCurrentReinsuranceYear());
    useEffect(() => {
        globalDispatch({
            type: 'update',
            payload: {
                key: 'crop_year',
                value: currentYear
            },
        });
    }, [currentYear])

    // --- --- ---

    const [positionSummaryData, setPositionSummaryData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const _loadPositionSummaryData = async () => {
        try {
            if (globalState?.agent_token && !globalState?.producer_token) {
                setIsLoading(true);

                const _positionSummaryFilter = {
                    agent_token: globalState?.agent_token,
                    year: currentYear,
                    producer_token: producer_token || globalState?.producer_token,
                };
                // This is agent function. Do not specify producer_token or you will get permissions error.
                const _apiConfig = {
                    agent_token: globalState?.agent_token,
                };
                const _positionSummaryData = await UsersApiHelper.users_search("PositionSummary", _positionSummaryFilter, _apiConfig);
                let _filteredPositionSummaryData = _positionSummaryData;
                if (filter?.commodity_name?.length) {
                    _filteredPositionSummaryData = _positionSummaryData
                        .filter(obj => filter?.commodity_name?.includes(obj.commodity_name));
                }

                setPositionSummaryData(_filteredPositionSummaryData);
                setIsLoading(false);
            } else {
                setIsLoading(true);

                const _positionSummaryFilter = {
                    year: currentYear,
                    producer_token: producer_token ? producer_token : globalState?.producer_token,
                };
                // This is ADMIN function. Do not specify producer_token or agent_token or you will get permissions error.
                const _apiConfig = {};
                const _positionSummaryData = await UsersApiHelper.users_search("PositionSummary", _positionSummaryFilter, _apiConfig);
                let _filteredPositionSummaryData = _positionSummaryData;
                if (filter?.commodity_name?.length) {
                    _filteredPositionSummaryData = _positionSummaryData
                        .filter(obj => filter?.commodity_name?.includes(obj.commodity_name));
                }

                setPositionSummaryData(_filteredPositionSummaryData);
                setIsLoading(false);
            }
        } catch (err) {
            setIsLoading(false);
            setPositionSummaryData(null);
            handleError(err);
        }
    }

    // ---

    const [salesByCategory, setSalesByCategory] = useState(null);
    const [sales, setSales] = useState(null);
    const [isLoadingSalesByCategory, setIsLoadingSalesByCategory] = useState(false);
    const _loadSalesByCategory = async () => {
        try {
            if (globalState?.agent_token && !globalState?.producer_token) {
                setIsLoading(true);

                const _positionSummaryDataFilter = {
                    agent_token: globalState?.agent_token,
                    year: currentYear,
                    producer_token: producer_token || globalState?.producer_token,
                };
                // This is agent function. Do not specify producer_token or you will get permissions error.
                const _apiConfig = {
                    agent_token: globalState?.agent_token,
                };
                const _positionSummaryData = await UsersApiHelper.users_search("PositionSummarySalesByCategory", _positionSummaryDataFilter, _apiConfig);
                let _filteredPositionSummaryData = _positionSummaryData;
                if (filter?.commodity_name?.length) {
                    _filteredPositionSummaryData = _positionSummaryData
                        .filter(obj => filter?.commodity_name?.includes(obj.commodity_name));
                }

                setSalesByCategory(_filteredPositionSummaryData);
                setIsLoadingSalesByCategory(false);
            } else {
                setIsLoadingSalesByCategory(true);

                const _positionSummaryDataFilter = {
                    year: currentYear,
                    producer_token: producer_token ? producer_token : globalState?.producer_token,
                };
                // This is ADMIN function. Do not specify producer_token or agent_token or you will get permissions error.
                const _apiConfig = {};
                const _positionSummaryData = await UsersApiHelper.users_search("PositionSummarySalesByCategory", _positionSummaryDataFilter, _apiConfig);
                let _filteredPositionSummaryData = _positionSummaryData;
                if (filter?.commodity_name?.length) {
                    _filteredPositionSummaryData = _positionSummaryData
                        .filter(obj => filter?.commodity_name?.includes(obj.commodity_name));
                }

                setSalesByCategory(_filteredPositionSummaryData);
                setIsLoadingSalesByCategory(false);
            }
        } catch (err) {
            setIsLoadingSalesByCategory(false);
            setSalesByCategory(null);
            handleError(err);
        }
    }

    const getSalesRowsByCommodity = useCallback((commodity_name) => {
        if (sales?.length) {
            return sales.filter(s => s.commodity_name == commodity_name);
        } else {
            return [];
        }
    }, [sales]);

    const _loadSales = async () => {
        try {

            setIsLoadingCurrent(true);
            // Get Sales:
            const _salesFilter = {
                crop_year: currentYear,
                producer_token: producer_token || globalState?.producer_token,
                is_active: true
            };
            const _sales = await UsersApiHelper.users_search("Sale", _salesFilter, globalState);
            let _filteredSales = _sales;

            // Add dummy fields:
            _filteredSales = _filteredSales.map(s => {
                if (s?.category == SALE_CATEGORY_CASH || s?.category == SALE_CATEGORY_HTA) {
                    s.price_cash = (s?.price || 0) + (s?.basis || 0);
                } else {
                    s.price_cash = null;
                }
                return s;
            });

            setSales(_filteredSales);
            setIsLoading(false);
            setIsLoadingCurrent(false);
        } catch (err) {
            setSales(null);
            setIsLoading(false);
            setIsLoadingCurrent(false);
            handleError(err);
        }
    };

    const formatDate = (isoDateString) => {
        const date = new Date(isoDateString);
        return date.toLocaleDateString('default', { year: 'numeric', month: 'short', day: 'numeric' });
        // Example output: "Aug 23, 2024"
    };

    const formatDateTime = (isoDateString) => {
        const date = new Date(isoDateString);
        // Example output: "Aug 23, 4:00 pm"
        return date.toLocaleDateString('default', { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: '2-digit' });
    };

    const handleRowDelete_Sales = useCallback(async (row) => {
        confirmDialogActions.confirm({
            title: "Delete this entry?",
            prompt: "Are you sure you want to delete this entry?",
            promptDetail: null,
            callback: () => {
                _handleRowDeleteIntern_Sales(row);
            }
        })
    }, [confirmDialogActions]);

    const _handleRowDeleteIntern_Sales = async (row) => {
        const _row = { ...row };
        _row.is_active = false;

        updateSale(_row);
        _loadSales();
    };

    const sales_columns = useMemo(() => [

        {
            field: 'trade_date',
            headerName: 'Trade Date',
            type: 'date',
            minWidth: 150,
            valueFormatter: (params) =>
                formatDate(params.value),
            flex: 1
        },
        {
            field: 'financial_institution_name',
            headerName: 'Buyer',
            minWidth: 200,
            flex: 2
        },
        {
            field: 'commodity_name',
            headerName: 'Commodity',
            minWidth: 0,
            flex: 1
        },
        {
            field: 'category',
            headerName: 'Category',
            minWidth: 0,
        },
        {
            field: 'symbol',
            headerName: 'Symbol',
            minWidth: 120,
            flex: 1
        },
        {
            field: 'price',
            headerName: 'Futures',
            minWidth: 120,
            flex: 1,
            align: 'right',
            headerAlign: 'right',
            valueFormatter: (params) =>
                params?.value ? formatNumber(params?.value, 4) : "0",
        },
        {
            field: 'basis',
            headerName: 'Basis',
            type: 'number',
            minWidth: 120,
            flex: 1,
            align: 'right',
            valueFormatter: (params) =>
                params?.value ? formatNumber(params?.value, 4) : "0",
        },
        {
            field: 'price_cash',
            headerName: 'Cash',
            minWidth: 120,
            flex: 1,
            align: 'right',
            headerAlign: 'right',
            valueFormatter: (params) =>
                params?.value ? formatNumber(params?.value, 4) : "0",
        },
        {
            field: 'amount',
            headerName: 'Quantity',
            type: 'number',
            minWidth: 0,
            valueFormatter: (params) =>
                formatNumber(params.value, 0),
            align: 'right',
            headerAlign: 'right',
            flex: 0
        },
        {
            field: 'delivery_period_start',
            headerName: 'Delivery',
            minWidth: 120,
            flex: 2,
            valueFormatter: (params) =>
                (sales?.length) ? formatDeliveryPeriod(sales?.find(s => s.id == params?.id)) : null
        },
        {
            field: 'contract_number',
            headerName: 'Contract',
            minWidth: 0,
            flex: 2,
        },
        {
            field: 'revenue',
            headerName: 'Revenue',
            minWidth: 0,
            flex: 2,
            renderCell: (params) => (
                <Box>
                    <abbr title={formatDateContext(params?.row?.market_value_timestamp)}>
                        {formatPrice(params.value, 0)}
                    </abbr>
                </Box>
            ),
            align: 'right',
            headerAlign: 'right',
        },
        {
            field: 'actions',
            headerName: 'Actions',
            minWidth: 150,
            flex: 1,
            sortable: false,
            renderCell: (params) => (
                <>
                    <IconButton onClick={() => setEditingSale(params?.row)}>
                        <EastOutlinedIcon />
                    </IconButton>
                    <IconButton onClick={() => handleRowDelete_Sales(params?.row)}>
                        <DeleteForeverOutlined />
                    </IconButton>
                </>
            )
        }
    ], [sales]);

    const [transactionHistory, setTransactionHistory] = useState(null);
    const _loadTransactionHistory = async () => {
        try {
            if (globalState?.agent_token && !globalState?.producer_token) {
                setIsLoading(true);

                const _transactionHistoryFilter = {
                    agent_token: globalState?.agent_token,
                    year: currentYear,
                    producer_token: producer_token || globalState?.producer_token,
                    is_posted: true,
                };
                // This is agent function. Do not specify producer_token or you will get permissions error.
                const _apiConfig = {
                    agent_token: globalState?.agent_token,
                };
                const transactionHistory = await UsersApiHelper.users_search("UserTransactionHistory", _transactionHistoryFilter, _apiConfig);

                let _filteredTransactions = transactionHistory;
                // if (filter?.commodity_name?.length) {
                //     _filteredTransactions = transactionHistory
                //         .filter(obj => filter?.commodity_name?.includes(obj.commodity_name));
                // }

                // Order by trade_date ASC
                _filteredTransactions = (_filteredTransactions.sort((a, b) => new Date(a.trade_date) - new Date(b.trade_date)));

                setTransactionHistory(_filteredTransactions);
                setIsLoading(false);
            } else if (globalState?.user_role == USER_ROLE_ADMIN) {
                setIsLoading(true);

                const _transactionHistoryFilter = {
                    year: currentYear,
                    producer_token: producer_token,
                    is_posted: true,
                };
                // This is ADMIN function. Do not specify producer_token or agent_token or you will get permissions error.
                const _apiConfig = {};
                const transactionHistory = await UsersApiHelper.users_search("UserTransactionHistory", _transactionHistoryFilter, _apiConfig);

                let _filteredTransactions = transactionHistory;

                if (filter?.commodity_name_list?.length) {
                    _filteredTransactions = transactionHistory
                        .filter(obj => filter?.commodity_name_list?.includes(obj.commodity_name));
                }

                if (filter?.search_contract_number) {
                    _filteredTransactions = _filteredTransactions
                        .filter(obj => obj.contract_number?.toLowerCase().includes(filter?.search_contract_number?.toLowerCase()));
                }

                // Order by trade_date ASC
                _filteredTransactions = (_filteredTransactions.sort((a, b) => new Date(a.trade_date) - new Date(b.trade_date)));
                setTransactionHistory(_filteredTransactions);
                setIsLoading(false);
            }
        } catch (err) {
            // setIsLoadingTransactionHistory(false);
            // setTransactionHistory(null);
            console.warn(err);
            enqueueSnackbar(err?.detail || err?.message || err, { preventDuplicate: true, variant: 'error' });
        }
    }

    const getTransactionHistoryRowsByCommodity = useCallback((commodity_name) => {
        if (transactionHistory?.length) {
            return transactionHistory.filter(
                s => s.crop_profile_commodity_name == commodity_name
                    && s.offset_match_id == null
            );
        } else {
            return [];
        }
    }, [transactionHistory]);

    const getTransactionHistoryTotal_Revenue = useCallback((commodity_name) => {
        if (!commodity_name) {
            return 0;
        }
        const transactions = getTransactionHistoryRowsByCommodity(commodity_name);
        if (transactions?.length) {
            const _filteredTransactions = transactionHistory?.filter(
                s => s.crop_profile_commodity_name == commodity_name
            );

            return _filteredTransactions.reduce((acc, curr) => {
                return acc + (curr?.market_value_open || curr?.market_value_closed || 0);
            }, 0);
        } else {
            return 0;
        }
    }, [transactionHistory]);

    const calculateFuturesOptionsHedgedTotal = useCallback((commodity_name) => {
        if (!commodity_name) {
            return 0;
        }

        const transactions = getTransactionHistoryRowsByCommodity(commodity_name);
        if (transactions?.length) {
            // Reduce hedge_quantity * multiplication_factor
            return transactions.reduce((acc, curr) => {
                return acc + (curr.hedge_quantity * curr.multiplication_factor);
            }, 0);
        } else {
            return 0;
        }
    }, [transactionHistory]);

    const getTransactionHistoryRowsByMatch = useCallback((id) => {
        if (transactionHistory?.length) {
            return transactionHistory.filter(
                s => s.offset_match_id == id
            );
        } else {
            return [];
        }
    }, [transactionHistory]);

    // ---

    const formatDefaultCropProfile = (row) => {
        if (row?.crop_profile_year && row?.crop_profile_commodity_name) {
            if (row?.crop_profile_name == row?.crop_profile_commodity_name || row?.crop_profile_name == null) {
                return row?.crop_profile_year + " - " + row?.crop_profile_commodity_name;
            } else {
                return row?.crop_profile_year + " - " + row?.crop_profile_commodity_name + " - " + row?.crop_profile_name;
            }
        } else {
            return row?.crop_profile || null;
        }
    }

    const formatCropProfile = (cp) => {
        if (cp?.year && cp?.commodity_name && cp?.name) {
            if (cp?.name == cp?.commodity_name) {
                return cp?.year + " - " + cp?.commodity_name;
            } else {
                return cp?.year + " - " + cp?.commodity_name + " - " + cp?.name;
            }
        } else {
            return row?.crop_profile || null;
        }
    }

    const getMonthName = useCallback((monthNumber) => {
        const date = new Date();
        date.setMonth(monthNumber - 1); // JavaScript months are 0-indexed
        return date.toLocaleString('default', { month: 'short' }); // Get short month name (e.g., "Dec")
    }, []);

    const updateTransactionHistory = async (row) => {
        try {
            const api = new ApiHelper({
                baseUrl: process.env.REACT_APP_DATANAC_USERS_API_URL_BASE,
                config: {},
                sessionManager: _sessionManager,
                // No caching:
                _instance: null
            });

            await api.callPost("marketing/UserTransactionHistory", row, globalState);
            await _loadTransactionHistory();
        } catch (err) {
            handleError(err);
        }
    }

    // ---

    const reportData = useMemo(() => {
        if (positionSummaryData?.length && !salesByCategory?.length) {
            return positionSummaryData;
        } else if (positionSummaryData?.length && salesByCategory?.length) {
            const _reportData = [...positionSummaryData];
            for (const ps of _reportData) {
                const _salesByCategory = salesByCategory.filter(sbc => sbc.commodity_name == ps.commodity_name);
                ps.salesByCategory = _salesByCategory || [];

                for (const currentSaleByCategory of ps.salesByCategory) {
                    // Calculate percentage of production
                    if (Boolean(ps.production)) {
                        currentSaleByCategory.percentage_of_production = (currentSaleByCategory.amount / ps.production);
                    } else {
                        currentSaleByCategory.percentage_of_production = null;
                    }
                }
            }
            return _reportData;
        } else {
            return null;
        }
    }, [positionSummaryData, salesByCategory]);

    // ---

    const calculateMarketingTotal = useCallback((commodity_name) => {
        if (!commodity_name) {
            return 0;
        }

        const currentCountyPractice = reportData?.find(r => r.commodity_name == commodity_name);
        // Loop salesByCategory sum ps?.amount * (ps?.price_per_unit || currentCountyPractice?.market_last_price)
        if (currentCountyPractice?.salesByCategory?.length) {
            let totalQty = 0;
            const salesTotal = currentCountyPractice.salesByCategory.reduce((acc, curr) => {
                totalQty += curr?.amount;
                return acc + (curr?.amount * (curr?.price_per_unit || currentCountyPractice?.market_last_price));
            }, 0);

            const unsoldTotal = (currentCountyPractice?.production - totalQty) * (currentCountyPractice?.market_last_price);

            return salesTotal + unsoldTotal;
        } else {
            return 0;
        }
    }, [reportData, transactionHistory]);

    /// --- --- ---

    const handleError = (err) => {
        console.warn(err);
        enqueueSnackbar(err.detail || err.message || JSON.stringify(err), { preventDuplicate: true, variant: 'error' });
    }

    const [budgetCountyPractices, setBudgetCountyPractices] = useState(null);
    const [isLoadingBudgetCountyPractices, setIsLoadingBudgetCountyPractices] = useState(true);
    const loadBudgetCountyPractices = async () => {
        try {
            const _budgetFilter = {
                // state_name, county_name,
                // commodity_name, type_name, practice_name,
                year: currentYear,
                status: BUDGET_STATUS_ACTIVE,
                is_active: true,
                producer_token: producer_token
            }
            // if (commodity_name) { _budgetFilter.commodity_name = commodity_name; }
            // if (type_name) { _budgetFilter.type_name = type_name; }
            // if (practice_name) { _budgetFilter.practice_name = practice_name; }

            const _allCountyPractices = await UsersApiHelper.selectBudgetCountyPractices(_budgetFilter, globalState);
            setBudgetCountyPractices(_allCountyPractices);

            setIsLoadingBudgetCountyPractices(false);
        } catch (err) {
            setIsLoadingBudgetCountyPractices(false);
            setBudgetCountyPractices([]);
            handleError(err);
        }
    }

    useEffect(() => {
        loadBudgetCountyPractices();
    }, [globalState?.producer_token])

    // ---

    const loadUniqueCropPractices = async () => {
        try {
            // Get unique:
            const _uniqueCropPractices = getUniqueCropPractices(budgetCountyPractices);

            const _loadedCropPractices = [];
            for (const cp of _uniqueCropPractices) {
                const _compareBudget = (cp?.children?.length && cp.children[0]);
                // const futures = await selectFuturesByBudget({
                //     year: currentYear,
                //     ..._compareBudget
                // });
                // if (futures?.length) {
                //     cp.futures = futures;
                // } else {
                //     cp.futures = null;
                // }

                cp.unit_of_measure_abbreviation = await getUnitOfMeasureAbbreviation({
                    reinsurance_year: currentYear,
                    year: currentYear,
                    ..._compareBudget
                }) || "";

                _loadedCropPractices.push(cp);
            }

            setUniqueCropPractices(_loadedCropPractices);
        } catch (err) {
            setUniqueCropPractices([]);
            handleError(err);
        }
    };

    const [uniqueCropPractices, setUniqueCropPractices] = useState(null);
    useEffect(() => {
        if (budgetCountyPractices?.length > 0 && uniqueCropPractices == null) {
            loadUniqueCropPractices();
        }
    }, [budgetCountyPractices])

    //useCallback to cache getUnitOfMeasureAbbreviation
    const _getUnitOfMeasureAbbreviation = useCallback((countyPractice) => {
        // Find in uniqueCropPractices:
        const _uniqueCropPractice = uniqueCropPractices?.find(cp =>
            cp.commodity_name == countyPractice.commodity_name
        );
        if (_uniqueCropPractice) {
            return _uniqueCropPractice.unit_of_measure_abbreviation;
        } else {
            return "";
        }
    }, [uniqueCropPractices]);

    //useCallback to cache getUnitOfMeasureAbbreviation
    const _getFuturesByCountyPractice = useCallback((countyPractice) => {
        // Find in uniqueCropPractices:
        const _uniqueCropPractice = uniqueCropPractices?.find(cp =>
            cp.commodity_name == countyPractice.commodity_name
        );
        if (_uniqueCropPractice?.futures?.length) {
            return _uniqueCropPractice.futures[0];
        } else {
            return null;
        }
    }, [uniqueCropPractices]);

    // --- --- ---

    const [filter, dispatchFilter] = useReducer(propUpdateReducer, {
        commodity_name: default_commodity_name ? [default_commodity_name] : null,
        filter_agent_token: globalState?.filter_agent_token || [globalState?.agent_token] || [],
        filter_is_posted: false,
    });

    const filterHandleChangeState = async (event, value) => {
        if (value == null && event.target.value != null) {
            value = event.target.value
        }

        dispatchFilter({
            type: 'update',
            payload: { key: event.target.name, value: value },
        });
    }

    useEffect(() => {
        if (currentYear) {
            _loadPositionSummaryData();
            _loadSalesByCategory();
            _loadTransactionHistory();
            _loadSales();
        }
    }, [currentYear, filter]);

    // ---

    const [expanded, setExpanded] = React.useState(false);

    // Not a function but returns a function
    const handleChangeAccordionExpanded = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    // ---

    const [editingSale, setEditingSale] = useState(null);

    const handleClickNewSale = async () => {
        setEditingSale({
            producer_token: globalState.producer_token,
            crop_year: currentYear,
            trade_date: new Date(),
        });
    }

    const [editingFuturesOptions, setEditingFuturesOptions] = useState(null);

    const handleClickNewFuture = async () => {
        setEditingFuturesOptions({
            producer_token: globalState.producer_token,
            crop_year: currentYear,
            trade_date: new Date(),
        });
    }

    const isValid = () => {
        return true;
    }

    const updateSale = async (sale) => {
        try {
            const api = new ApiHelper({
                baseUrl: process.env.REACT_APP_DATANAC_USERS_API_URL_BASE,
                config: {},
                sessionManager: _sessionManager,
                // No caching:
                _instance: null
            });

            if (!sale.producer_token) {
                sale.producer_token = producer_token || globalState.producer_token;
            }
            if (!sale.crop_year) {
                sale.crop_year = currentYear;
            }

            await api.callPost("marketing/Sale", sale, globalState);
        } catch (err) {
            handleError(err);
        }
    }

    const futuresOptionsUpdate = async (futuresOptions) => {
        try {
            const api = new ApiHelper({
                baseUrl: process.env.REACT_APP_DATANAC_USERS_API_URL_BASE,
                config: {},
                sessionManager: _sessionManager,
                // No caching:
                _instance: null
            });
            await api.callPost("broker/MarketTransactionHistory", futuresOptions, globalState);
        } catch (err) {
            handleError(err);
        }
    }
    // --- --- ---

    const [editingCropProfile, setEditingCropProfile] = useState(null);

    const getCropProfileFromTransactionHistory = (row) => {
        const _cropProfile = {
            id: v4(),
            producer_token: row?.producer_token,
            is_active: true,
        };
        if (row?.symbol_year && row?.commodity_name) {
            let _cropProfileYear = row?.symbol_year;
            if (row?.commodity_reference_month_term) {
                _cropProfileYear += row?.commodity_reference_month_term;
            }
            if (row?.commodity_reference_month && row?.symbol_month >= row?.commodity_reference_month) {
                _cropProfileYear++;
            }
            _cropProfile.year = _cropProfileYear;
            _cropProfile.commodity_name = row?.commodity_name;
        }

        setSelectedTransaction(row.id);

        return _cropProfile;
        // return null;
    }

    useEffect(() => {
        loadCropProfiles();
    }, [producer_token, globalState])

    const [cropProfiles, setCropProfiles] = useState([]);
    const loadCropProfiles = async () => {
        try {
            const _cropProfiles = await UsersApiHelper.users_search("CropProfile", { producer_token, is_active: true }, globalState);

            setCropProfiles(_cropProfiles);

            return _cropProfiles;
        } catch (error) {
            enqueueSnackbar(`Failed to fetch crop profiles: ${error.message}`, { variant: 'error' });
            setCropProfiles(null``)
            return [];
        }
    };

    const [loadingStates, setLoadingStates] = useState({}); // To manage loading for each row
    const fetchCropProfilesForRow = useCallback(() => {
        try {
            const _formattedCropProfiles = cropProfiles.map(cp => {
                const cropProfileDescription = cp.year + " - " + cp.commodity_name +
                    (cp?.name && cp?.name !== cp?.commodity_name ? " - " + cp.name : "");
                return cropProfileDescription;
            });

            return _formattedCropProfiles;
        } catch (error) {
            enqueueSnackbar(`Failed to fetch crop profiles: ${error.message}`, { variant: 'error' });
            return [];
        }
    }, [cropProfiles]);

    const handleChange_CropProfile = async (_newCropProfile) => {
        try {
            const api = new ApiHelper({
                baseUrl: process.env.REACT_APP_DATANAC_USERS_API_URL_BASE,
                config: {},
                sessionManager: _sessionManager,
                // No caching:
                _instance: null
            });
            await api.callPost("marketing/CropProfile", _newCropProfile, globalState);
            updateCropProfiles({});
        } catch (err) {
            handleError(err);
        }
    }

    // ---

    const handleDispatchRowChange = useCallback(async (id, key, value) => {
        const _transactionHistory = [...transactionHistory];
        const _index = _transactionHistory.findIndex(hist => hist.id == id);
        if (_index > -1) {
            if (key == "crop_profile" && value) {
                // Lookup crop profile from producerCropProfiles[producer_token]
                if (cropProfiles?.length) {
                    const _cropProfile = cropProfiles?.find(cp => value == formatCropProfile(cp));
                    if (_cropProfile) {
                        _transactionHistory[_index]["crop_profile_commodity_name"] = _cropProfile?.commodity_name;
                        _transactionHistory[_index]["crop_profile_year"] = _cropProfile?.year;
                        _transactionHistory[_index]["crop_profile_name"] = _cropProfile?.name;

                        confirmDialogActions.confirm({
                            title: "Change Crop Profile",
                            prompt: "Do you wish to change the Crop Profile for this record?",
                            promptDetail: null,
                            callback: () => {
                                _transactionHistory[_index][key] = value;

                                updateTransactionHistory(_transactionHistory[_index]);//Do not wait.
                                setTransactionHistory(_transactionHistory);
                            }
                        })
                    }
                }
            }
            // else {
            //     updateTransactionHistory(_transactionHistory[_index]);//Do not wait.
            //     setTransactionHistory(_transactionHistory);
            // }
        }
    }, [transactionHistory, setTransactionHistory, cropProfiles]);

    // ---

    const handleToggleNoteVisibility = (id, currentCountyPractice, rowIndex) => {
        const rowData = getTransactionHistoryRowsByCommodity(currentCountyPractice?.commodity_name);

        const _transactionHistory = [...transactionHistory];
        const _index = _transactionHistory.findIndex(hist => hist.id == id);

        _transactionHistory[_index].showNote = !_transactionHistory[_index].showNote;
        setTransactionHistory(_transactionHistory);
    };

    const handleNoteChange = (e, row) => {
        const { value } = e.target;
        if (value) {
            row.note = value;

            const _transactionHistory = [...transactionHistory];
            const _index = _transactionHistory.findIndex(hist => hist.id == row.id);

            _transactionHistory[_index].note = value;
            setTransactionHistory(_transactionHistory);
            updateTransactionHistory(row);
        }
    };

    // --- --- ---

    return (
        <Box sx={{ minHeight: "800px", maxWidth: "99%" }}>
            <WorkspaceHeader
                title={'Position Summary' + (reportData?.length ? " - " + formatProducerName(reportData[0] || "") : "")}
                breadCrumbs={[{
                    title: 'Marketing',
                    path: producer_token ? '/broker/position_summary' : '',
                    icon: <FarmVaultIcon />
                }]}
            />

            <Grid container>
                <Grid item xs={12}>
                    <Stack direction="row" spacing={2} className='headerWithMenuButton marketing-position'>
                        <DataFormSelect
                            value={filter.commodity_name || []}
                            name="commodity_name"
                            isRequired={false}
                            onChange={filterHandleChangeState}
                            objectName="Commodity"
                            itemFilter={{ reinsurance_year: String(currentYear) }}
                            itemGetKey={(item) => item.commodity_name}
                            itemGetLabel={(item) => item.commodity_name}
                            doFetch={currentYear != null}
                            isLoading={false}
                            multiple={true}
                            limitTags={isSmall ? 2 : 3}
                            autoSelect={false}
                        />
                        <PopupState variant="popover" popupId="demo-popup-menu">
                            {popupState => (
                                <>
                                    <Button variant="outlined" {...bindTrigger(popupState)} className='year-filter'>
                                        {currentYear} <ArrowDropDownOutlined />
                                    </Button>
                                    <Menu {...bindMenu(popupState)}>
                                        {getBudgetYears()?.map((year, index) => (
                                            <MenuItem key={index} onClick={() => {
                                                setCurrentYear(year);
                                                popupState.close();
                                            }}>
                                                {year}
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </>
                            )}
                        </PopupState>
                        {isLoadingCurrent && <CircularProgress />}
                    </Stack>
                </Grid>
            </Grid>

            <Typography variant='h6'>
                {Boolean(reportData?.length) && formatProducerName(reportData[0])}
            </Typography>

            {Boolean(reportData?.length) && reportData?.map((currentCountyPractice, idx) => (
                <div key={idx}>
                    <h2>{currentCountyPractice.commodity_name}</h2>
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table" className='marketing-table'>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Acres</TableCell>
                                    <TableCell>Yield (avg)</TableCell>
                                    <TableCell>Production</TableCell>
                                    <TableCell>Insured Guarantee</TableCell>
                                    <TableCell>Hedged</TableCell>
                                    <TableCell>Board</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow hover role="checkbox" tabIndex={-1}>
                                    <TableCell>
                                        {formatNumber(currentCountyPractice?.reported_acreage, 0)}
                                    </TableCell>
                                    <TableCell>
                                        {formatNumber(currentCountyPractice?.yield_average, 0)}
                                        {" " + _getUnitOfMeasureAbbreviation(currentCountyPractice)?.toLowerCase()}
                                    </TableCell>
                                    <TableCell>
                                        {formatNumber(currentCountyPractice?.production, 0)}
                                        {" " + _getUnitOfMeasureAbbreviation(currentCountyPractice)?.toLowerCase()}
                                        <br /><br />
                                        {!Boolean(currentCountyPractice?.production)
                                            ? "-- %"
                                            : formatPercentage(
                                                currentCountyPractice?.amount / currentCountyPractice?.production
                                                , 0)}
                                        {" "} sold
                                    </TableCell>
                                    <TableCell>
                                        {formatNumber(currentCountyPractice?.production_guarantee)}
                                        {" " + _getUnitOfMeasureAbbreviation(currentCountyPractice)?.toLowerCase()}
                                        <br /><br />
                                        {!Boolean(currentCountyPractice?.production_guarantee)
                                            ? "-- %"
                                            : formatPercentage((
                                                currentCountyPractice?.sales_amount / currentCountyPractice?.production_guarantee
                                                , 0))} sold
                                    </TableCell>
                                    <TableCell>
                                        {formatNumber(currentCountyPractice?.unit_quantity_hedge)}
                                        {" " + _getUnitOfMeasureAbbreviation(currentCountyPractice)?.toLowerCase()}
                                        <br /><br />
                                        {!Boolean(currentCountyPractice?.production)
                                            ? "-- %"
                                            : formatPercentage(
                                                currentCountyPractice?.unit_quantity_hedge / currentCountyPractice?.production
                                                , 0)} of production
                                    </TableCell>
                                    <TableCell>
                                        {currentCountyPractice?.market_symbol}
                                        <br /><br />
                                        {formatPrice(currentCountyPractice?.market_last_price, 4)}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <br />

                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table" className='marketing-table'>
                            <TableHead>
                                <TableRow className='tablerow-dark'>
                                    <TableCell>Cash Summary - {currentCountyPractice.commodity_name || ''}</TableCell>
                                    <TableCell>Qty</TableCell>
                                    <TableCell>% Prod.</TableCell>
                                    <TableCell>Price / Unit</TableCell>
                                    <TableCell>Revenue</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(() => {
                                    let totalQty = 0;
                                    let totalProd = 0;
                                    let totalBudget = 0;
                                    let totalRevenue = 0;

                                    let totalPercentageOfProduction = 0;

                                    return (
                                        <>
                                            {Boolean(currentCountyPractice?.salesByCategory?.length) &&
                                                [SALE_CATEGORY_CASH, SALE_CATEGORY_HTA]
                                                    .map((category, idx) => {
                                                        const ps = currentCountyPractice?.salesByCategory?.find(s => s.category == category);

                                                        if (ps) {
                                                            // Accumulate totals
                                                            totalQty += ps?.amount;
                                                            totalBudget += ps?.price;
                                                            totalRevenue += ps?.revenue;

                                                            if (currentCountyPractice?.production) {
                                                                totalPercentageOfProduction = (currentCountyPractice?.production == 0) ? null : (totalQty / currentCountyPractice?.production);
                                                            } else {
                                                                totalPercentageOfProduction = null;
                                                            }
                                                        }

                                                        return (
                                                            <TableRow key={idx} hover role="checkbox" tabIndex={-1}>
                                                                <TableCell>{category}</TableCell>
                                                                <TableCell>{formatNumber(ps?.amount)}</TableCell>
                                                                <TableCell>{!Boolean(currentCountyPractice?.production) ? "-- %"
                                                                    : formatPercentage(
                                                                        ps?.amount
                                                                        / currentCountyPractice?.production, 0)
                                                                }</TableCell>
                                                                <TableCell>{ps && formatPrice(ps?.price_per_unit, 4)}</TableCell>
                                                                <TableCell>{formatPrice(ps?.revenue, 0)}</TableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                            <TableRow hover role="checkbox" tabIndex={-1} className='cash-summary-total tablerow-dark'>
                                                <TableCell>Sold</TableCell>
                                                <TableCell>{formatNumber(totalQty)}</TableCell>
                                                <TableCell>{!Boolean(currentCountyPractice?.production) ? "-- %"
                                                    : formatPercentage(
                                                        totalQty
                                                        / currentCountyPractice?.production, 0)
                                                }</TableCell>
                                                <TableCell>{!Boolean(totalQty)
                                                    ? null : formatPrice(totalRevenue / totalQty, 4)}</TableCell>
                                                <TableCell>{formatPrice(totalRevenue, 0)}</TableCell>
                                            </TableRow>


                                            {Boolean(currentCountyPractice?.salesByCategory?.length) &&
                                                [SALE_CATEGORY_BASIS, SALE_CATEGORY_NO_PRICE].map((category, idx) => {
                                                    const ps = currentCountyPractice?.salesByCategory?.find(s => s.category == category);

                                                    if (ps) {
                                                        // Accumulate totals
                                                        totalQty += ps?.amount;

                                                        totalBudget += ps?.price;
                                                        totalRevenue += ps?.revenue;

                                                        if (currentCountyPractice?.production) {
                                                            totalPercentageOfProduction = (currentCountyPractice?.production == 0) ? null : (totalQty / currentCountyPractice?.production);
                                                        } else {
                                                            totalPercentageOfProduction = null;
                                                        }
                                                    }

                                                    return (
                                                        <TableRow key={"category" + category + idx} hover role="checkbox" tabIndex={-1}>
                                                            <TableCell>{category}</TableCell>
                                                            <TableCell>{formatNumber(ps?.amount)}</TableCell>
                                                            <TableCell>{!Boolean(currentCountyPractice?.production) ? "-- %"
                                                                : formatPercentage(
                                                                    ps?.amount
                                                                    / currentCountyPractice?.production, 0)
                                                            }</TableCell>
                                                            <TableCell>{ps && formatPrice(ps?.price_per_unit || currentCountyPractice?.market_last_price, 4)}</TableCell>
                                                            <TableCell>
                                                                {formatPrice(
                                                                    ps?.amount * (ps?.price_per_unit || currentCountyPractice?.market_last_price)
                                                                    , 0)}
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            <TableRow hover role="checkbox" tabIndex={-1}>
                                                <TableCell>Unsold</TableCell>
                                                <TableCell>{formatNumber(currentCountyPractice?.production - totalQty)}</TableCell>
                                                <TableCell>{!Boolean(currentCountyPractice?.production) ? "-- %"
                                                    : formatPercentage(
                                                        (currentCountyPractice?.production - totalQty)
                                                        / currentCountyPractice?.production, 0)
                                                }</TableCell>
                                                <TableCell>{formatPrice(currentCountyPractice?.market_last_price, 4)}</TableCell>
                                                <TableCell>
                                                    {formatPrice(
                                                        (currentCountyPractice?.production - totalQty) * (currentCountyPractice?.market_last_price)
                                                        , 0)}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow hover role="checkbox" tabIndex={-1} className='cash-summary-total tablerow-dark'>
                                                <TableCell>Unsold Total</TableCell>
                                                <TableCell>{formatNumber(
                                                    (currentCountyPractice?.salesByCategory?.find(s => s.category == SALE_CATEGORY_BASIS)?.amount || 0)
                                                    + (currentCountyPractice?.salesByCategory?.find(s => s.category == SALE_CATEGORY_NO_PRICE)?.amount || 0)
                                                    + (currentCountyPractice?.production - totalQty)
                                                )}</TableCell>
                                                <TableCell>{!Boolean(currentCountyPractice?.production) ? "-- %"
                                                    : formatPercentage(
                                                        ((currentCountyPractice?.salesByCategory?.find(s => s.category == SALE_CATEGORY_BASIS)?.amount || 0)
                                                            + (currentCountyPractice?.salesByCategory?.find(s => s.category == SALE_CATEGORY_NO_PRICE)?.amount || 0)
                                                            + (currentCountyPractice?.production - totalQty))
                                                        / currentCountyPractice?.production, 0)
                                                }</TableCell>
                                                <TableCell>{formatPrice(
                                                    (currentCountyPractice?.production - totalQty) == 0 ?
                                                        0
                                                        : (((currentCountyPractice?.salesByCategory?.find(s => s.category == SALE_CATEGORY_BASIS)?.amount || 0)
                                                            * (currentCountyPractice?.salesByCategory?.find(s => SALE_CATEGORY_BASIS)?.price_per_unit || 0))
                                                            + ((currentCountyPractice?.salesByCategory?.find(s => s.category == SALE_CATEGORY_NO_PRICE)?.amount || 0)
                                                                * (currentCountyPractice?.market_last_price || 0))
                                                            + ((currentCountyPractice?.production - totalQty)
                                                                * (currentCountyPractice?.market_last_price || 0)))
                                                        / (
                                                            (currentCountyPractice?.salesByCategory?.find(s => s.category == SALE_CATEGORY_BASIS)?.amount || 0)
                                                            + (currentCountyPractice?.salesByCategory?.find(s => s.category == SALE_CATEGORY_NO_PRICE)?.amount || 0)
                                                            + (currentCountyPractice?.production - totalQty)
                                                        )
                                                    , 4)}</TableCell>
                                                <TableCell>{formatPrice(
                                                    ((currentCountyPractice?.salesByCategory?.find(s => s.category == SALE_CATEGORY_BASIS)?.amount || 0)
                                                        * (currentCountyPractice?.salesByCategory?.find(s => SALE_CATEGORY_BASIS)?.price_per_unit || 0))
                                                    + ((currentCountyPractice?.salesByCategory?.find(s => s.category == SALE_CATEGORY_NO_PRICE)?.amount || 0)
                                                        * (currentCountyPractice?.market_last_price || 0))
                                                    + ((currentCountyPractice?.production - totalQty)
                                                        * (currentCountyPractice?.market_last_price || 0))
                                                    , 0)}</TableCell>
                                            </TableRow>


                                            <TableRow hover role="checkbox" tabIndex={-1}>
                                                <TableCell>Futures/Options Hedged</TableCell>
                                                <TableCell>{formatNumber(calculateFuturesOptionsHedgedTotal(currentCountyPractice?.commodity_name))}</TableCell>
                                                <TableCell>{!Boolean(currentCountyPractice?.production) ? "-- %"
                                                    : formatPercentage(
                                                        calculateFuturesOptionsHedgedTotal(currentCountyPractice?.commodity_name)
                                                        / currentCountyPractice?.production, 0)
                                                }</TableCell>
                                                <TableCell>{formatPrice(
                                                    getTransactionHistoryTotal_Revenue(currentCountyPractice?.commodity_name) / currentCountyPractice?.production
                                                    , 4)}</TableCell>
                                                <TableCell>{formatPrice(getTransactionHistoryTotal_Revenue(currentCountyPractice?.commodity_name), 0)}</TableCell>
                                            </TableRow>
                                            <TableRow hover role="checkbox" tabIndex={-1}>
                                                <TableCell>Total Hedged Position</TableCell>
                                                <TableCell>{formatNumber(
                                                    (currentCountyPractice?.salesByCategory?.find(s => s.category == SALE_CATEGORY_CASH)?.amount || 0)
                                                    + (currentCountyPractice?.salesByCategory?.find(s => s.category == SALE_CATEGORY_HTA)?.amount || 0)
                                                    + calculateFuturesOptionsHedgedTotal(currentCountyPractice?.commodity_name)
                                                )}</TableCell>
                                                <TableCell>{!Boolean(currentCountyPractice?.production) ? "-- %"
                                                    : formatPercentage(
                                                        ((currentCountyPractice?.salesByCategory?.find(s => s.category == SALE_CATEGORY_CASH)?.amount || 0)
                                                            + (currentCountyPractice?.salesByCategory?.find(s => s.category == SALE_CATEGORY_HTA)?.amount || 0)
                                                            + calculateFuturesOptionsHedgedTotal(currentCountyPractice?.commodity_name))
                                                        / currentCountyPractice?.production, 0)
                                                }</TableCell>
                                                <TableCell>&nbsp;</TableCell>
                                                <TableCell>&nbsp;</TableCell>
                                            </TableRow>
                                            <TableRow hover role="checkbox" tabIndex={-1} className='cash-summary-total tablerow-dark'>
                                                <TableCell>Marketing Total</TableCell>
                                                <TableCell>{formatNumber(currentCountyPractice?.production)}</TableCell>
                                                <TableCell>
                                                    &nbsp;
                                                </TableCell>
                                                <TableCell>{formatPrice(
                                                    calculateMarketingTotal(currentCountyPractice?.commodity_name) / currentCountyPractice?.production
                                                    , 4)}</TableCell>
                                                <TableCell>{formatPrice(
                                                    calculateMarketingTotal(currentCountyPractice?.commodity_name)
                                                    , 0)}</TableCell>
                                            </TableRow>
                                        </>
                                    );
                                })()}
                            </TableBody>
                        </Table>
                    </TableContainer>


                    <Accordion className='riskSummaryAccordion' sx={{ mt: 1 }} defaultExpanded>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            Cash Sales
                        </AccordionSummary>
                        <AccordionDetails>
                            <TilleyDataGrid
                                rows={getSalesRowsByCommodity(currentCountyPractice?.commodity_name) || []}
                                columns={sales_columns}
                                pageSizeOptions={[5, 10, 15, 20, 25, 50, 100]}
                                defaultPageSize={isMobile ? 5 : 10}
                                gridKey='position-summary-report-cash-sales'
                                getRowId={(row) => row.id}
                                isLoading={isLoadingCurrent}
                            />
                        </AccordionDetails>
                    </Accordion>


                    <Accordion className='riskSummaryAccordion' defaultExpanded>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            Futures & Options
                        </AccordionSummary>
                        <AccordionDetails>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ fontWeight: "bold" }}>Open</TableCell>
                                        <TableCell sx={{ fontWeight: "bold" }}>Position</TableCell>
                                        <TableCell sx={{ fontWeight: "bold" }}>Date</TableCell>
                                        <TableCell sx={{ textAlign: "right", fontWeight: "bold" }}>Qty</TableCell>
                                        <TableCell sx={{ textAlign: "right", fontWeight: "bold" }}>Open Qty</TableCell>
                                        <TableCell sx={{ fontWeight: "bold" }}>Commodity</TableCell>
                                        {/* <TableCell>Month/Year</TableCell> */}
                                        <TableCell sx={{ fontWeight: "bold" }}>Security</TableCell>
                                        <TableCell sx={{ textAlign: "right", fontWeight: "bold" }}>Price</TableCell>
                                        <TableCell sx={{ textAlign: "right", fontWeight: "bold" }}>Current Price</TableCell>
                                        <TableCell sx={{ textAlign: "right", fontWeight: "bold" }}>Gain/Loss</TableCell>
                                        <TableCell sx={{ fontWeight: "bold" }}>Note</TableCell>
                                        <TableCell sx={{ fontWeight: "bold" }}>Tracer</TableCell>
                                        <TableCell sx={{ fontWeight: "bold" }}>Crop Profile</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {getTransactionHistoryRowsByCommodity(currentCountyPractice?.commodity_name).map((row, index) => {
                                        let security = "";
                                        const SECURITY_SUBTYPE_CALL = "C";
                                        const SECURITY_SUBTYPE_PUT = "P";
                                        if (row?.security_subtype_code == SECURITY_SUBTYPE_CALL) {
                                            security = "Call";
                                        } else if (row?.security_subtype_code == SECURITY_SUBTYPE_PUT) {
                                            security = "Put";
                                        }

                                        return (<Fragment key={row.id}>
                                            <TableRow className='tablerow-light'>
                                                <TableCell>{row.is_open ? "Open" : "Closed"}</TableCell>
                                                <TableCell>
                                                    {row.buy_sell_code == BUY_SELL_CODE_BUY ? 'Buy ' : 'Sell '}
                                                    {security}
                                                </TableCell>
                                                <TableCell>{formatDate(row.trade_date)}</TableCell>
                                                <TableCell sx={{ textAlign: "right" }}>{formatNumberFinancial(row.quantity * row.multiplication_factor, 0)}</TableCell>
                                                <TableCell sx={{ textAlign: "right" }}>{formatNumberFinancial(row.quantity_open * row.multiplication_factor, 0)}</TableCell>
                                                <TableCell>{row.security_desc_line_1}</TableCell>
                                                {/* <TableCell>{getMonthName(row.symbol_month)} - {row.symbol_year?.toString().slice(-2)}</TableCell> */}
                                                <TableCell>{row.security_subtype_code}</TableCell>
                                                <TableCell sx={{ textAlign: "right" }}>{formatPrice(row.trade_price, 4)}</TableCell>
                                                <TableCell sx={{ textAlign: "right" }}>{row.quote_open ? formatPrice(row.quote_open, 4) : null}</TableCell>
                                                <TableCell sx={{ textAlign: "right" }}>{row.market_value_open ? formatPrice(row.market_value_open, 2) : null}</TableCell>
                                                <TableCell>
                                                    <Box>
                                                        {!row.showNote && (
                                                            <>
                                                                <span>
                                                                    {row.note}
                                                                </span>
                                                            </>
                                                        )}
                                                        <IconButton
                                                            onClick={() => handleToggleNoteVisibility(row.id, currentCountyPractice, index)}
                                                            size="small"
                                                        >
                                                            <NoteAddIcon />
                                                        </IconButton>
                                                    </Box>
                                                    <Box>
                                                        {row.showNote && (
                                                            <>
                                                                <StatefulControlledTextField
                                                                    label=""
                                                                    placeholder='Notes'
                                                                    variant="outlined"
                                                                    size="small"
                                                                    inputProps={{ maxLength: 100 }}
                                                                    defaultValue={row.note || ""}
                                                                    InputLabelProps={{ shrink: false }}
                                                                    onChange={(e) => handleNoteChange(e, row)}
                                                                    fullWidth
                                                                />
                                                            </>
                                                        )}
                                                    </Box>
                                                </TableCell>
                                                <TableCell>{row.tracer_number}</TableCell>
                                                <TableCell sx={{ p: "0px !important" }}>
                                                    <Box sx={{ mb: 1, pr: 1, width: "100%" }}>
                                                        <FormControl fullWidth>
                                                            <Autocomplete
                                                                name="crop_profile"
                                                                options={fetchCropProfilesForRow() || []}
                                                                multiple={false}
                                                                loading={loadingStates[row?.producer_token] || false}
                                                                value={formatDefaultCropProfile(row) || null}
                                                                // disabled={row?.is_posted != null}
                                                                onChange={(event, value) => handleDispatchRowChange(row.id, 'crop_profile', value)}
                                                                autoHighlight
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        name="crop_profile"
                                                                        {...params}
                                                                        required
                                                                        onChange={null}
                                                                        InputLabelProps={{ shrink: true }}
                                                                    />
                                                                )}
                                                                renderOption={(props, option) => {
                                                                    const { key, ...optionProps } = props;
                                                                    return (
                                                                        <li key={key} {...optionProps}>
                                                                            <Box>{option}</Box>
                                                                        </li>
                                                                    );
                                                                }}
                                                            // PaperComponent={({ children }) => {
                                                            //     return (
                                                            //         <Paper>
                                                            //             {children}
                                                            //             <Button
                                                            //                 color="primary"
                                                            //                 variant="filled"
                                                            //                 fullWidth
                                                            //                 sx={{ justifyContent: "flex-start", pl: 2 }}
                                                            //                 onMouseDown={() => {
                                                            //                     setEditingCropProfile(getCropProfileFromTransactionHistory(row));
                                                            //                 }}
                                                            //             >
                                                            //                 + Add New
                                                            //             </Button>
                                                            //         </Paper>
                                                            //     );
                                                            // }}
                                                            />
                                                        </FormControl>
                                                    </Box>
                                                </TableCell>
                                            </TableRow>

                                            {/* indented & nested getTransactionHistoryRowsByMatch(row.id).map*/}
                                            {getTransactionHistoryRowsByMatch(row.id).map((row) => {
                                                return (<>
                                                    <TableRow key={row.id}>
                                                        <TableCell>&nbsp;</TableCell>
                                                        <TableCell>&nbsp;</TableCell>
                                                        <TableCell>{formatDate(row.trade_date)}</TableCell>
                                                        <TableCell sx={{ textAlign: "right" }}>{formatNumberFinancial(row.quantity * row.multiplication_factor, 0)}</TableCell>
                                                        <TableCell sx={{ textAlign: "right" }}>{formatNumberFinancial(row.quantity_open * row.multiplication_factor, 0)}</TableCell>
                                                        <TableCell>{row.security_desc_line_1}</TableCell>
                                                        {/* <TableCell>{getMonthName(row.symbol_month)} - {row.symbol_year?.toString().slice(-2)}</TableCell> */}
                                                        <TableCell>{row.security_subtype_code}</TableCell>
                                                        <TableCell sx={{ textAlign: "right" }}>{formatPrice(row.trade_price, 4)}</TableCell>
                                                        <TableCell sx={{ textAlign: "right" }}>{row.quote_open ? formatPrice(row.quote_open, 4) : null}</TableCell>
                                                        <TableCell sx={{ textAlign: "right" }}>{row.market_value_closed ? formatPrice(row.market_value_closed, 2) : null}</TableCell>
                                                        <TableCell>{row.note}</TableCell>
                                                        <TableCell>{row.tracer_number}</TableCell>
                                                        <TableCell sx={{ p: "0px !important" }}>
                                                            <Box sx={{ mb: 1, pr: 1, width: "100%" }}>
                                                                <FormControl fullWidth>
                                                                    <Autocomplete
                                                                        name="crop_profile"
                                                                        options={fetchCropProfilesForRow() || []}
                                                                        multiple={false}
                                                                        value={row?.crop_profile || null}
                                                                        disabled={row?.is_posted != null}
                                                                        onChange={(event, value) => {
                                                                            handleDispatchRowChange(row.id, 'crop_profile', value);
                                                                        }}
                                                                        autoHighlight
                                                                        renderInput={(params) => (
                                                                            <TextField
                                                                                name="crop_profile"
                                                                                {...params}
                                                                                required
                                                                                onChange={null}
                                                                                InputLabelProps={{ shrink: true }}
                                                                            />
                                                                        )}
                                                                        renderOption={(props, option) => {
                                                                            const { key, ...optionProps } = props;
                                                                            return (
                                                                                <li key={key} {...optionProps}>
                                                                                    <Box>{option}</Box>
                                                                                </li>
                                                                            );
                                                                        }}
                                                                        PaperComponent={({ children }) => {
                                                                            return (
                                                                                <Paper>
                                                                                    {children}
                                                                                    <Button
                                                                                        color="primary"
                                                                                        variant="filled"
                                                                                        fullWidth
                                                                                        sx={{ justifyContent: "flex-start", pl: 2 }}
                                                                                        onMouseDown={() => {
                                                                                            setEditingCropProfile(getCropProfileFromTransactionHistory(row));
                                                                                        }}
                                                                                    >
                                                                                        + Add New
                                                                                    </Button>
                                                                                </Paper>
                                                                            );
                                                                        }}
                                                                    />
                                                                </FormControl>
                                                            </Box>
                                                        </TableCell>
                                                    </TableRow>
                                                </>);
                                            })}
                                        </Fragment>)
                                    })}

                                    {/* Total Row */}
                                    <TableRow>
                                        <TableCell colSpan={3} sx={{ fontWeight: "bold" }}>Total</TableCell>
                                        <TableCell sx={{ textAlign: "right", fontWeight: "bold" }}>
                                            {formatNumberFinancial(getTransactionHistoryRowsByCommodity(currentCountyPractice?.commodity_name).reduce((total, row) => total + row.quantity * row.multiplication_factor, 0), 0)}
                                        </TableCell>
                                        <TableCell sx={{ textAlign: "right", fontWeight: "bold" }}>
                                            {formatNumberFinancial(getTransactionHistoryRowsByCommodity(currentCountyPractice?.commodity_name).reduce((total, row) => total + row.quantity_open * row.multiplication_factor, 0), 0)}
                                        </TableCell>
                                        <TableCell colSpan={4}></TableCell>
                                        <TableCell sx={{ textAlign: "right", fontWeight: "bold" }}>
                                            {formatPrice(
                                                getTransactionHistoryRowsByCommodity(currentCountyPractice?.commodity_name).reduce((total, row) => total + (row.market_value_open || 0), 0),
                                                2
                                            )}
                                        </TableCell>
                                        <TableCell colSpan={3}></TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </AccordionDetails>
                    </Accordion>

                    <Divider />
                </div>
            ))
            }


            <WorkspaceActionBar content={<>
                <Box>
                    <Button className='add-sale' variant="contained" onClick={handleClickNewSale} disabled={!isValid()}>
                        <AddCircleOutline sx={{ mr: 1 }} />
                        Add Sale
                    </Button>
                    {/* <Button className='add-futures' style={{ marginLeft: '15px' }} variant="contained" onClick={handleClickNewFuture}>
                        <AddCircleOutline sx={{ mr: 1 }} />
                        Add Futures
                    </Button> */}
                </Box>
            </>} />


            <Dialog open={Boolean(editingSale != null)}>
                <SalesEditDialog
                    producer_token={producer_token}//Override producer_token
                    onChange={updateSale} onClose={() => { setEditingSale(null); setIsLoading(true); _loadSales(); _loadSalesByCategory(); }}
                    sale={editingSale}
                    crop_year={currentYear} />
            </Dialog>

            <Dialog open={Boolean(editingFuturesOptions != null)}>
                <FuturesOptionsEditDialog
                    producer_token={producer_token}
                    onChange={futuresOptionsUpdate} onClose={() => { setEditingFuturesOptions(null); _loadTransactionHistory(); }}
                    futureOptions={editingFuturesOptions} />
            </Dialog>

            <Dialog open={Boolean(editingCropProfile)}>
                <CropProfileEditDialog cropProfile={editingCropProfile} onClose={() => setEditingCropProfile(null)} onChange={handleChange_CropProfile} />
            </Dialog>

            {confirmDialogComponent}
        </Box>
    );
}