import { useDialog } from '@datanac/datanac-mui-toolkit'
import InfoOutlined from '@mui/icons-material/InfoOutlined'
import { Card, CardContent, Chip, Divider, Grid, Skeleton, Stack, Typography, useMediaQuery } from '@mui/material'
import { useArcPlc } from 'api-toolkit/useArcPlc'
import { UsersApiHelper } from 'api/ApiHelper'
import { AppContext } from 'App'
import { ArcPlcIcon } from 'components/Icons/Icons'
import WorkspaceHeader from 'components/Workspaces/WorkspaceHeader'
import { SkeletonWrapper } from 'mui-toolkit/SkeletonWrapper'
import { useSnackbar } from 'notistack'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { formatNumber, formatPrice } from 'utility/formatters'
import { ArcPlcService, MARKET_YEAR_AVERAGE_PRICE_STATUS_PROJECTED, PRACTICE_NO_PRACTICE_SPECIFIED } from './ArcPlcService'

export default function ArcPlcBaseAcreWorkspace() {
    const { globalState, globalDispatch, session } = useContext(AppContext);

    const {
        crop_year,
        location_state_name, location_county_name,
        commodity_name,
        producer_farm_base_acres_id
    } = useParams();
    const navigate = useNavigate();

    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'), { noSsr: true });
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { component: confirmDialogComponent, actions: confirmDialogActions } = useDialog();
    const [isLoading, setIsLoading] = useState(true);

    // --- --- ---

    const [baseAcres, setBaseAcres] = useState(null);
    const arcPlc = useArcPlc({ baseAcres });

    const handleError = (err) => {
        console.warn(err);
        enqueueSnackbar(err.detail || err.message || JSON.stringify(err), { preventDuplicate: true, variant: 'error' });
    }

    const loadBaseAcres = async () => {
        try {
            setIsLoading(true);

            const baseAcresFilter = {
                producer_token: globalState.producer_token,
                location_state_name,
                location_county_name,
                id: producer_farm_base_acres_id,
                crop_year: crop_year,
                is_active: true
            };
            const _baseAcres = await UsersApiHelper.selectProducerFarmBaseAcres(baseAcresFilter, globalState);
            if (_baseAcres?.length) {
                setBaseAcres(_baseAcres);
            } else {
                setBaseAcres(null);
            }

            setIsLoading(false);
        } catch (err) {
            setBaseAcres(null);
            setIsLoading(false);
            handleError(err);
        }
    }

    useEffect(() => {
        if (producer_farm_base_acres_id && globalState?.producer_token) {
            loadBaseAcres();
        }
    }, [producer_farm_base_acres_id, globalState?.producer_token])

    // ---

    const [PLCPaymentRates, setPLCPaymentRates] = useState(null);
    const [isLoadingPLCPaymentRates, setIsLoadingPLCPaymentRates] = useState(true);
    const loadPLCPaymentRates = async () => {
        try {
            setIsLoadingPLCPaymentRates(true);

            const { actions: arcPlcActions } = new ArcPlcService({
                apiConfig: globalState,

                crop_year: crop_year,
                location_state_name,
                location_county_name,

                baseAcres: baseAcres,
            });

            const _PLCPaymentRates = await arcPlcActions.getPLCPaymentRates();
            if (_PLCPaymentRates?.length) {
                setPLCPaymentRates(_PLCPaymentRates[0]?.PLCPaymentRates);
            }

            setIsLoadingPLCPaymentRates(false);
        } catch (err) {
            setPLCPaymentRates(null);
            setIsLoadingPLCPaymentRates(false);
            handleError(err);
        }
    }

    useEffect(() => {
        if (baseAcres?.length && globalState?.producer_token) {
            loadPLCPaymentRates();
        }
    }, [baseAcres, globalState?.producer_token])

    // ---

    const [EffectiveReferencePrices, setEffectiveReferencePrices] = useState(null);
    const [isLoadingEffectiveReferencePrices, setIsLoadingEffectiveReferencePrices] = useState(true);
    const loadEffectiveReferencePrices = async () => {
        try {
            setIsLoadingEffectiveReferencePrices(true);

            const { actions: arcPlcActions } = new ArcPlcService({
                apiConfig: globalState,

                crop_year: crop_year,
                location_state_name,
                location_county_name,

                baseAcres: baseAcres,
            });

            const _EffectiveReferencePrices = await arcPlcActions.getEffectiveReferencePrices();
            if (_EffectiveReferencePrices?.length) {
                setEffectiveReferencePrices(_EffectiveReferencePrices[0]?.EffectiveReferencePrices);
            }

            setIsLoadingEffectiveReferencePrices(false);
        } catch (err) {
            setEffectiveReferencePrices(null);
            setIsLoadingEffectiveReferencePrices(false);
            handleError(err);
        }
    }

    useEffect(() => {
        if (baseAcres?.length && globalState?.producer_token) {
            loadEffectiveReferencePrices();
        }
    }, [baseAcres, globalState?.producer_token])

    // ---

    const [ARCCOPrices, setARCCOPrices] = useState(null);
    const [isLoadingARCCOPrices, setIsLoadingARCCOPrices] = useState(true);
    const loadARCCOPrices = async () => {
        try {
            setIsLoadingARCCOPrices(true);

            const { actions: arcPlcActions } = new ArcPlcService({
                apiConfig: globalState,

                crop_year: crop_year,
                location_state_name, location_county_name,

                baseAcres: baseAcres,
            });

            const _arccoPrices = await arcPlcActions.getARCCOPrices();
            if (_arccoPrices?.length && _arccoPrices[0]?.ARCCOPrices?.length) {
                setARCCOPrices(_arccoPrices[0]?.ARCCOPrices[0]);
            }

            setIsLoadingARCCOPrices(false);
        } catch (err) {
            setARCCOPrices(null);
            setIsLoadingARCCOPrices(false);
            handleError(err);
        }
    }

    useEffect(() => {
        if (baseAcres?.length && globalState?.producer_token) {
            loadARCCOPrices();
        }
    }, [baseAcres, globalState?.producer_token])

    // ---

    const [marketYearAveragePrices, setMarketYearAveragePrices] = useState(null);
    const [isLoadingMarketYearAveragePrices, setIsLoadingMarketYearAveragePrices] = useState(true);
    const loadMarketYearAveragePrices = async () => {
        try {
            setIsLoadingMarketYearAveragePrices(true);

            const { actions: arcPlcActions } = new ArcPlcService({
                apiConfig: globalState,

                crop_year: crop_year,
                location_state_name, location_county_name,

                baseAcres: baseAcres,
            });

            const _marketYearAveragePrices = await arcPlcActions.getMarketYearAveragePrices();
            if (_marketYearAveragePrices?.length && _marketYearAveragePrices[0]?.MarketYearAveragePrices?.length) {
                setMarketYearAveragePrices(_marketYearAveragePrices[0]?.MarketYearAveragePrices[0]);
            }

            setIsLoadingMarketYearAveragePrices(false);
        } catch (err) {
            setMarketYearAveragePrices(null);
            setIsLoadingMarketYearAveragePrices(false);
            handleError(err);
        }
    }

    useEffect(() => {
        if (baseAcres?.length && globalState?.producer_token) {
            loadMarketYearAveragePrices();
        }
    }, [baseAcres, globalState?.producer_token])

    // ---

    const [ARCCOYields, setARCCOYields] = useState(null);
    const [isLoadingARCCOYields, setIsLoadingARCCOYields] = useState(true);
    const loadARCCOYields = async () => {
        try {
            setIsLoadingARCCOYields(true);

            const { actions: arcPlcActions } = new ArcPlcService({
                apiConfig: globalState,

                crop_year: crop_year,
                location_state_name, location_county_name,

                baseAcres: baseAcres,
            });

            const _arccoYields = await arcPlcActions.getARCCOYields();
            if (_arccoYields?.length) {
                setARCCOYields(_arccoYields[0]);
            }

            setIsLoadingARCCOYields(false);
        } catch (err) {
            setARCCOYields(null);
            setIsLoadingARCCOYields(false);
            handleError(err);
        }
    }

    useEffect(() => {
        if (baseAcres?.length && globalState?.producer_token) {
            loadARCCOYields();
        }
    }, [baseAcres, globalState?.producer_token])

    // --- --- ---

    const [producerFarm, setProducerFarm] = useState({});
    const loadProducerFarm = async () => {
        try {
            if (baseAcres && baseAcres?.length && baseAcres[0].producer_farm_id) {
                const producerFilter = {
                    producer_token: globalState.producer_token,
                    id: baseAcres[0].producer_farm_id,
                    is_active: true
                };
                const farms = await UsersApiHelper.selectProducerFarms(producerFilter, globalState);
                if (farms?.length) {
                    setProducerFarm(farms[0]);
                }
            }
        } catch (err) {
            setProducerFarm({});
            handleError(err);
        }
    }

    useEffect(() => {
        if (baseAcres?.length && globalState?.producer_token) {
            loadProducerFarm();
        }
    }, [baseAcres?.length, globalState?.producer_token]);

    // --- --- ---

    return (
        <>
            <WorkspaceHeader
                title={!Boolean(producerFarm?.fsa_farm_number && baseAcres?.length) ? "ARC/PLC"
                    : producerFarm?.fsa_farm_number + (producerFarm?.farm_name ? (" - " + producerFarm?.farm_name) : "")
                    + ((" - " + (baseAcres?.[0]?.commodity_name || "")))
                }
                breadCrumbs={[
                    {
                        title: 'ARC/PLC ' + crop_year,
                        path: '/arcplc',
                        icon: <ArcPlcIcon fontSize='3pt' />
                    },
                    {
                        title: location_county_name + ", " + location_state_name,
                        path: `/arcplc/${crop_year}/${location_state_name}/${location_county_name}`,
                        icon: null
                    },
                    {
                        title: commodity_name,
                        path: `/arcplc/${crop_year}/${location_state_name}/${location_county_name}/${commodity_name}`,
                        icon: null
                    },
                ]}
            />
            <Typography variant='h1'>
                Program Comparison
            </Typography>

            <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={5} xl={3}>
                    <Card sx={{ height: "100%" }}>
                        <CardContent>
                            <Stack direction="column" spacing={1} sx={{ justifyContent: "space-between", height: "100%" }}>
                                {!arcPlc?.isLoading && <>
                                    {(arcPlc?.totals?.arcPayment == null) &&
                                        <Typography variant='h1'>--</Typography>}
                                    {(arcPlc?.totals?.arcPayment == 0) &&
                                        <Chip icon={<InfoOutlined />} label={"No ARC-CO payment projected"} />}
                                    {Boolean(arcPlc?.totals?.arcPayment) &&
                                        <Typography variant='h1'>{formatPrice(arcPlc?.totals?.arcPayment, 0)}</Typography>}
                                </>}
                                {arcPlc?.isLoading &&
                                    <Typography variant='h1'>
                                        <Skeleton />
                                    </Typography>}
                                <Typography variant='body1'>
                                    ARC-CO<br />
                                    PAYMENT
                                </Typography>
                            </Stack>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} md={6} lg={5} xl={3}>
                    <Card sx={{ height: "100%" }}>
                        <CardContent>
                            <Stack direction="column" spacing={1} sx={{ justifyContent: "space-between", height: "100%" }}>
                                {!arcPlc?.isLoading && <>
                                    {(arcPlc?.totals?.plcPayment == null) &&
                                        <Typography variant='h1'>--</Typography>}
                                    {(arcPlc?.totals?.plcPayment == 0) &&
                                        <Chip icon={<InfoOutlined />} label={"No PLC payment projected"} />}
                                    {Boolean(arcPlc?.totals?.plcPayment) &&
                                        <Typography variant='h1'>{formatPrice(arcPlc?.totals?.plcPayment, 0)}</Typography>}
                                </>}
                                {arcPlc?.isLoading &&
                                    <Typography variant='h1'>
                                        <Skeleton />
                                    </Typography>}
                                <Typography variant='body1'>
                                    PLC<br />
                                    PAYMENT
                                </Typography>
                            </Stack>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            <Divider />

            <Typography variant='h1'>
                Based On
            </Typography>
            <Stack direction="row" spacing={1}>
                <Stack direction="column">
                    <SkeletonWrapper isLoading={isLoadingARCCOPrices}>
                        <Typography variant='h1' sx={{ whiteSpace: "pre" }}>
                            {formatPrice(marketYearAveragePrices?.price, 4)}
                        </Typography>
                    </SkeletonWrapper>
                    <Typography variant='body1'>
                        MYA
                        {marketYearAveragePrices?.price_status == MARKET_YEAR_AVERAGE_PRICE_STATUS_PROJECTED && <>
                            <br />(PROJECTED)
                        </>}
                    </Typography>
                </Stack>
                <Divider orientation='vertical' flexItem />

                <Stack direction="column">
                    <Typography variant='h1'>
                        {!arcPlc?.isLoading && <>
                            {formatNumber(arcPlc?.totals?.base_acreage, 0)}
                        </>}
                        {arcPlc?.isLoading && <>
                            <Skeleton />
                        </>}
                    </Typography>
                    <Typography variant='body1'>
                        BASE ACRES
                    </Typography>
                </Stack>

                <Divider orientation='vertical' flexItem />

                <Stack direction="column">
                    <Typography variant='h1'>
                        {!arcPlc?.isLoading && <>
                            {formatNumber(arcPlc?.totals?.expected_index_value, 2)}
                        </>}
                        {arcPlc?.isLoading && <>
                            <Skeleton />
                        </>}
                    </Typography>
                    <Typography variant='body1'>
                        COUNTY YIELD
                    </Typography>
                </Stack>
            </Stack>

            <Divider />

            <Typography variant='h1'>
                PLC
            </Typography>
            <Stack direction="row" spacing={1}>
                <Stack direction="column">
                    <SkeletonWrapper isLoading={isLoadingPLCPaymentRates}>
                        <Typography variant='h1' sx={{ whiteSpace: "pre" }}>
                            {formatPrice(EffectiveReferencePrices?.length && EffectiveReferencePrices[0]?.effective_reference_price, 4)}
                        </Typography>
                    </SkeletonWrapper>
                    <Typography variant='body1'>
                        ERP PRICE
                    </Typography>
                </Stack>

                <Divider orientation='vertical' flexItem />

                <Stack direction="column">
                    <Typography variant='h1'>
                        {!arcPlc?.isLoading && <>
                            {formatNumber(arcPlc?.totals?.plc_yield, 0)}
                        </>}
                        {arcPlc?.isLoading && <>
                            <Skeleton />
                        </>}
                    </Typography>
                    <Typography variant='body1'>
                        PLC YIELD
                    </Typography>
                </Stack>
            </Stack>

            <Divider />

            <Typography variant='h1'>
                ARC-CO
            </Typography>
            <Stack flexWrap="wrap" direction="row" columnGap={1} rowGap={1}>
                <Stack direction="column">
                    <Typography variant='h1' sx={{ whiteSpace: "pre" }}>
                        {formatPrice(ARCCOYields && ARCCOYields?.benchmark_price, 4)}
                    </Typography>
                    <Typography variant='body1'>
                        BENCHMARK PRICE
                    </Typography>
                </Stack>

                {Boolean(ARCCOYields?.length) && ARCCOYields?.map((arc, index) => <React.Fragment key={index}>
                    <Divider orientation='vertical' flexItem />
                    <Stack direction="column">
                        <Typography variant='h1'>
                            {formatNumber(arc?.benchmark_yield, 2)}
                        </Typography>
                        <Typography variant='body1' sx={{ textTransform: "uppercase" }}>
                            {arc?.practice_name != PRACTICE_NO_PRACTICE_SPECIFIED && <>
                                {arc?.practice_name}<br />
                            </>}
                            YIELD
                        </Typography>
                    </Stack>
                    <Divider orientation='vertical' flexItem />
                    <Stack direction="column">
                        <Typography variant='h1' sx={{ whiteSpace: "pre" }}>
                            {formatPrice(arc?.guarantee_revenue, 4)}
                        </Typography>
                        <Typography variant='body1' sx={{ textTransform: "uppercase" }}>
                            {arc?.practice_name != PRACTICE_NO_PRACTICE_SPECIFIED && <>
                                {arc?.practice_name}<br />
                            </>}
                            GUARANTEE /ac
                        </Typography>
                    </Stack>
                </React.Fragment>)}
            </Stack>

            {confirmDialogComponent}
        </>
    )
}
