export const BUY_SELL_CODE_BUY = "1";
export const BUY_SELL_CODE_SELL = "2";

export const useMarketingReport = ({
    
}) => {

    const [sales, setSales] = useState(null);

    const loadSales = async () => {
        return [];
    }

    const actions = {
        loadSales
    };

    return {
        sales,
        actions
    }
}
