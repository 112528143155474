/**
 * Black box functions for ARC-CO and PLC calculations.
 **/


const PLC_PAYMENT_PERCENTAGE = 0.85;
export function calculatePlcPayment({
    // Datanac / FSA-ARCPLC / PLCPaymentRates
    effective_reference_price, national_loan_rate, market_year_average_price,
    effective_price,//substitute for hypothetical test
    plc_yield,
    // Base Acres
    base_acreage,
    share_percent = 1.00
}) {
    // Step 1: Calculate PLC Payment Price (per bushel)
    const maxPriceOrLoanRate = Math.max(effective_price, national_loan_rate || 0, market_year_average_price || 0);
    const plcPaymentPrice = Math.max(0, effective_reference_price - maxPriceOrLoanRate);

    // Step 2: Calculate Payment Per Acre
    const plcPaymentPricePerAcre = plcPaymentPrice * plc_yield;

    // Step 3: Calculate Total Farm PLC Payment
    const totalPlcPayment = base_acreage * PLC_PAYMENT_PERCENTAGE * plcPaymentPricePerAcre;
    // PlcPayment factors share_percent.
    const plcPayment = base_acreage * PLC_PAYMENT_PERCENTAGE * plcPaymentPricePerAcre * share_percent;

    return {
        // PlcPayment factors share_percent.
        plcPayment,
        totalPlcPayment, plcPaymentPrice, plcPaymentPricePerAcre,
    };
}

export function calculateArcCoPayment({
    // Datanac / FSA-ARCPLC / ARCCOYields
    benchmark_price,
    benchmark_yield,

    actual_revenue,//substitute for hypothetical test

    // Base Acres
    base_acreage,
    share_percent
}) {
    const benchmark_revenue = benchmark_price * benchmark_yield;

    const BENCHMARK_REVENUE_PERCENTAGE = 0.86;
    const benchmark_guarantee = BENCHMARK_REVENUE_PERCENTAGE * benchmark_revenue;

    const MAX_PAYMENT_THRESHOLD = 0.10;
    const BASE_ACRE_PERCENTAGE = 0.85;

    let totalFarmPayment = null;
    if (actual_revenue < benchmark_guarantee) {
        const payment = (benchmark_guarantee - actual_revenue) * base_acreage * BASE_ACRE_PERCENTAGE;
        const maxPayment = MAX_PAYMENT_THRESHOLD * benchmark_revenue * base_acreage * BASE_ACRE_PERCENTAGE;
        totalFarmPayment = Math.min(payment, maxPayment);
    } else {
        totalFarmPayment = 0;
    }

    const arcPayment = totalFarmPayment * share_percent;
    
    return {
        // ARC-CO factors share_percent.
        arcPayment,// Factors share_percent.
        totalFarmPayment,// Does not factor share_percent.
        benchmark_guarantee, benchmark_revenue,
        actual_revenue
    };
}



export const ERROR_COULD_NOT_CALCULATE_ARCPLC = "Could not calculate ARC/PLC projections for this crop.";
/// Black box for both ARC-CO/PLC calculations
export const calculateArcPlcPayments = async ({
    // PLCPaymentRates
    effective_reference_price,
    effective_price,
    national_loan_rate,

    // ARCCOYields:
    benchmark_price,
    benchmark_yield,
    actual_revenue,

    // ProducerFarmBaseAcres:
    base_acreage,
    plc_yield,
    share_percent,
}) => {
    const plc = calculatePlcPayment({
        effective_reference_price,
        effective_price,
        national_loan_rate,
        plc_yield,

        base_acreage,
        share_percent
    });

    const arcco = calculateArcCoPayment({
        benchmark_price,
        benchmark_yield,
        actual_revenue,

        base_acreage,
        share_percent
    });

    return {
        arcco: arcco,
        plc: plc
    }

}
