import { Chat, Send } from "@mui/icons-material";
import { Alert, Box, Button, CircularProgress, Stack, Typography } from "@mui/material";
import _ from "lodash";
import { useState } from "react";
import { useSnackbar } from 'notistack';

export const SYSTEM_PROMPT_RAG = `
Please answer the users questions about Tilley Farm software, their farm operations, and their financials.
Strictly limit resutls to questions about the FARM DATA. Redirect other topics to questions about Tilley and the FARM DATA below.
Answer only in TXT or MD.
FARM DATA:
`;

export const ChatPopupHelper = ({
    userMessage,
    systemPrompt = "You are a helpful assistant.",
    onChatCompletion,
}) => {

    const API_URL_ROOT = "https://msvjtmxq32.execute-api.us-east-1.amazonaws.com/PROD/";
    // const API_URL_ROOT = "https://msvjtmxq32.execute-api.us-east-1.amazonaws.com/PROD/";
    // const API_URL_ROOT = "http://localhost:5210/";
    const { enqueueSnackbar } = useSnackbar();

    const sendChatCompletion = async (systemPrompt, _input) => {
        const __messages = [];
        __messages.push({ role: 'user', content: _input });

        setCurrentWorkingMessage("...");

        // const SYSTEM_PROMPT_LANGUAGE_ENGLISH = "Answer ONLY in English.";
        // const SYSTEM_PROMPT_LANGUAGE_SPANISH = "Responda en español, por favor.";
        // const languageSystemPrompt = languageCode === "es-MX" ? SYSTEM_PROMPT_LANGUAGE_SPANISH : SYSTEM_PROMPT_LANGUAGE_ENGLISH;

        const chatCompletion = await SendChatCompletion_api({
            systemPrompt: systemPrompt,
            messages: __messages.map(msg => ({ role: msg.role, content: msg.content })),
            isAssistantRequest: true,
        });
        // SynthesizeSpeech(chatCompletion)

        try {
            const _parseChatCompletionString = chatCompletion.replace("```json", "").replace("```", "");
            const _accounts = JSON.parse(_parseChatCompletionString);
            if (_accounts.length) {
                onChatCompletion && onChatCompletion(_accounts);
            }
            return (_accounts);
        } catch (error) {
            console.warn(error);
            if (chatCompletion?.length) {
                onChatCompletion && onChatCompletion(chatCompletion);
            }

            return chatCompletion;
        }
    }

    const [currentWorkingMessage, setCurrentWorkingMessage] = useState("");

    async function SendChatCompletion_api({ systemPrompt, messages, isAssistantRequest = false }) {

        try {
            const response = await fetch(process.env.REACT_APP_RGCHAT_API_URL_ROOT + `ChatCompletion`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ systemPrompt, messages })
            });

            if (!response.ok) {
                // Handle HTTP errors
                enqueueSnackbar("An error occurred: " + response.statusText, { preventDuplicate: true, variant: 'error' });
                return;
            }

            const reader = response.body.getReader();
            const decoder = new TextDecoder();
            let result = '';
            let cumulativeResult = '';
            while (true) {
                const { done, value } = await reader.read();
                if (done) {
                    break;
                }
                result += decoder.decode(value, { stream: true });

                cumulativeResult = result;

                // If result doesn't end with ] then add it:
                if (cumulativeResult[cumulativeResult.length - 1] !== "]") {
                    cumulativeResult += "]";
                }
                // Get JSON of result
                cumulativeResult = JSON.parse(cumulativeResult).join("");
                // console.log(cumulativeResult);

                isAssistantRequest && setCurrentWorkingMessage(cumulativeResult);
            }

            isAssistantRequest && setCurrentWorkingMessage(null);
            return cumulativeResult;
        } catch (error) {

            // Handle other errors (e.g., network errors)
            enqueueSnackbar("An error occurred: " + error.message, { preventDuplicate: true, variant: 'error' });
        }
    }

    // ---

    return <Box sx={{ border: "1px solid #2A52BE50;", backgroundColor: "skyblue", p: 1 }}>
        <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
            <Typography variant="body1" sx={{ color: "black" }}>{userMessage}</Typography>
            <Button variant="outlined" onClick={() => sendChatCompletion(systemPrompt, userMessage)}
                sx={{ alignSelf: "flex-end" }}>
                {Boolean(currentWorkingMessage) ? <CircularProgress size="1rem" /> : <Send />}
            </Button>
        </Stack>
    </Box>

}